<template>
  <div class="web-player-v2">
    <div class="initializing-overlay text-center" v-if="isLoading">
      <h1 class="loading-text">Initializing player. Please wait...</h1>
      <Loader />
    </div>

    <div v-else-if="unauthorized()" class="text-center pt-5">
      <div>
        <h1>Unable to connect this screen to the playlist you are trying to load.</h1>
        <div>
          <p>Detach the already attached screen to this playlist and reload this page.</p>

          <button @click.prevent="handleLinkReload" class="btn btn-warning">Reload</button>
        </div>
      </div>
    </div>

    <Layout v-else :layout="layout" :playlists="playlists" />
    <div class="highlight-overlay" v-if="selectedLayerId"></div>
    <div class="borders" :style="getSelectedLayerStyles()" v-if="selectedLayerId"></div>
  </div>
</template>

<script>
  import { mapState } from 'vuex';
  import Loader from '@/components/common/Loader.vue';
  import Layout from '@/components/player/Layout.vue';
  import { PLAYER_LOAD_LAYOUT } from '@/store/actions/player';
  import { SET_LAYOUT_DIMENSIONS } from '@/store/actions/layoutDesigner';
  import { SCREEN_ID_ENUM, SESSION_TOKEN_ENUM } from '@/constants';

  export default {
    name: 'player',
    components: {
      Layout,
      Loader,
    },

    props: {
      layout_id: {
        type: String,
        required: false,
        default: null,
      },

      selectedLayerId: {
        default: null,
      },

      playerDimmensions: {
        type: Object,
        default: () => ({
          width:
            window.innerWidth || document.documentElement.clientWidth || document.body.clientWidth,
          height:
            window.innerHeight ||
            document.documentElement.clientHeight ||
            document.body.clientHeight,
        }),
      },
    },

    computed: mapState({
      isLoading: (state) => state.player.loadingLayout,
      hasError: (state) => state.player.loadingLayoutError,
      layout: (state) => state.player.layout,
      playlists: (state) => state.player.playlists,
    }),

    methods: {
      unauthorized() {
        return !localStorage.getItem(SESSION_TOKEN_ENUM);
      },
      loadLayout() {
        this.$store.dispatch(PLAYER_LOAD_LAYOUT, {
          // screenId: this.$route.params.screenId,
          screenId: localStorage.getItem(SCREEN_ID_ENUM),
          loadChildren: true,
        });
      },

      handleLinkReload() {
        this.reloadWithCacheBusted();
      },

      reloadWithCacheBusted() {
        const port = window.location.port.trim() ? `:${window.location.port}` : '';
        const cacheBust = `?cacheBust=${Date.now()}`;

        window.location = `${window.location.protocol}//${window.location.hostname}${port}${cacheBust}${window.location.hash}`;
      },

      getSelectedLayerStyles() {
        let layers = this.$store.state.player.widgets;
        let selectedLayer = layers[this.selectedLayerId];

        if (!selectedLayer) {
          return {};
        }

        let position =
          selectedLayer.position[this.layout.settings.isHorizontal ? 'horizontal' : 'vertical'];

        let styles = {
          top: `${position.y}%`,
          left: `${position.x}%`,
          width: `${position.width}%`,
          height: `${position.height}%`,
        };

        return styles;
      },

      // Socket method
      sendDeviceInfo(deviceInfo) {
        // Emit device info
        const data = {
          type: 'device_info',
          auth: localStorage.getItem('user-session_token'),
          data: deviceInfo,
        };
        this.$socket.emit('device_info', data);
      },

      playerError(typeError, message) {
        // Emit player error
        const data = {
          type: typeError,
          auth: localStorage.getItem(SESSION_TOKEN_ENUM),
          error: message,
        };
        this.$socket.emit('player_error', data);
      },
    },

    sockets: {
      // Receive emit from server
      connect() {
        console.log('socket connected');
        this.$socket.emit('join', {
          auth: localStorage.getItem('user-session_token'),
        });
      },

      change_orientation(data) {
        this.$emit('changeOrientation', data.orientation); // Emit to wrap parent component
        this.$socket.emit('orientation_changed', data);
      },
      restart_player() {
        this.$emit('restartPlayer');
      },
    },

    provide() {
      return {
        selectedLayerId: () => this.selectedLayerId,
      };
    },

    created() {
      this.loadLayout();
    },

    mounted() {
      document.body.classList.add('web-player-body-v2');

      this.$store.commit(SET_LAYOUT_DIMENSIONS, this.playerDimmensions);
    },

    beforeDestroy() {
      document.body.classList.remove('web-player-body-v2');
    },
  };
</script>

<style lang="scss">
  body.web-player-body-v2 {
    margin: 0;
    padding: 0;
    height: 100vh;
    width: 100%;
    overflow: hidden;

    #mainAppWrapper {
      height: 100%;
      width: 100%;
      overflow: hidden;
      padding: 0;
      margin: 0;
    }
  }
</style>

<style lang="scss" scoped>
  .web-player-v2 {
    position: relative;
    width: 100%;
    height: 100vh;
    background-color: #000;
    color: #fff;
    overflow: hidden;

    ::v-deep .widget-item {
      position: absolute;
    }

    .highlight-overlay {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      background-color: rgba(41, 41, 41, 0.451);
      z-index: 1000;
    }

    .borders {
      position: absolute;
      border: 4px solid #e3003a;
      box-sizing: border-box;
      z-index: 2000;
    }
  }

  .initializing-overlay {
    height: 100vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }

  .loading-text {
    color: #fff7bc;
    font-family: 'Poppins';
    padding-bottom: 80px;
  }

  pre {
    font-size: 1rem;
    height: 100%;
    overflow: auto;
    background-color: #000;
    color: #fff;
  }
</style>
