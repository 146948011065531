import { ref } from 'vue';
import { getCloudfrontUrl } from '@/helpers/utils';
import { apiGetFonts } from '@/api/fonts';
import pLimit from 'p-limit';
import { preLoadFont } from '@/composables/usePicflowFonts/helper';
import { SentryLogger } from '@/utils/sentryLogger';

export function usePicflowFonts() {
  const MAX_ACTIVE_FONT_LOADS = 5;

  const isLoading = ref(false);

  const loadFonts = async (): Promise<void> => {
    isLoading.value = true;

    try {
      const limit = pLimit(MAX_ACTIVE_FONT_LOADS);

      const fonts = (await apiGetFonts()).map((font) => {
        return {
          key: font.key,
          name: font.name,
          format: font.key.split('.').pop()?.toLowerCase() ?? null,
        };
      });

      const preloadFonts: Promise<void>[] = [];
      for (let i = 0; i < fonts.length; i++) {
        const font = fonts[i];

        if (font.format === null) {
          SentryLogger.error(`Font key ${font.key} format is null won't preload`);
          continue;
        }

        preloadFonts.push(
          limit(async () => {
            const fontUrl = getCloudfrontUrl(font.key);
            try {
              await preLoadFont(fontUrl, font.format ?? '');
            } catch (error) {
              SentryLogger.error(`Failed to preload font ${fontUrl}`);
            }
          }),
        );
      }

      await Promise.all(preloadFonts);

      for (let j = 0; j < fonts.length; j++) {
        const font = fonts[j];

        const style = document.createElement('style');
        style.appendChild(
          document.createTextNode(
            `@font-face {
              font-family: ${font.name};
              src: local(${font.name}),
                   url(${getCloudfrontUrl(font.key)});
            }`,
          ),
        );

        document.head.appendChild(style);
      }
    } catch (error: any) {
      SentryLogger.error('Failed to load fonts', error);
    } finally {
      isLoading.value = false;
    }
  };

  return {
    isLoading,
    loadFonts,
  };
}
