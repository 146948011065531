import Vue from 'vue';
import { io } from 'socket.io-client';
import VueSocketIO from 'vue-socket.io';
import localforage from 'localforage';
import * as Sentry from '@sentry/vue';

import config from '@/config';
import router from '@/router';
import App from '@/App.vue';
import store from '@/store';
import { REFRESH_TOKEN_ENUM, SCREEN_ID_ENUM, SESSION_TOKEN_ENUM } from '@/constants';

import { BootstrapVue, IconsPlugin } from 'bootstrap-vue';

import 'bootstrap/dist/js/bootstrap';
import 'bootstrap/dist/css/bootstrap.css';

import 'bootstrap-vue/dist/bootstrap-vue.css';
import PicflowSentryPlugin from '@/plugins/picflowSentryPlugin';

const checkStorage = async (vueApp: any) => {
  const sessionToken = localStorage.getItem(SESSION_TOKEN_ENUM);
  const screenId = localStorage.getItem(SCREEN_ID_ENUM);
  const refreshToken = localStorage.getItem(REFRESH_TOKEN_ENUM);

  if (sessionToken && screenId && refreshToken) {
    vueApp.$mount('#app');
    return;
  }

  const forageSessionToken = await localforage.getItem(SESSION_TOKEN_ENUM);
  const forageScreenId = await localforage.getItem(SCREEN_ID_ENUM);
  const forageRefreshToken = await localforage.getItem(REFRESH_TOKEN_ENUM);

  if (forageSessionToken && forageScreenId && forageRefreshToken) {
    localStorage.setItem(SESSION_TOKEN_ENUM, forageSessionToken as string);
    localStorage.setItem(SCREEN_ID_ENUM, forageScreenId as string);
    localStorage.setItem(REFRESH_TOKEN_ENUM, forageRefreshToken as string);
  }

  vueApp.$mount('#app');
};

Sentry.init({
  Vue,
  dsn: config.sentry.dns,
  integrations: [
    new Sentry.BrowserTracing({
      tracePropagationTargets: [
        window.location.origin,
        config.apiUrl,
        config.socketUrl,
        config.imageBaseUrl,
      ],
      routingInstrumentation: Sentry.vueRouterInstrumentation(router),
    }),
    new Sentry.Replay({
      maskAllText: false,
      blockAllMedia: false,
      networkDetailAllowUrls: [
        window.location.origin,
        config.apiUrl,
        config.socketUrl,
        config.imageBaseUrl,
      ],
      networkResponseHeaders: ['Cache-Control'],
      networkRequestHeaders: ['Cache-Control'],
    }),
  ],
  tracesSampleRate: config.sentry.tracesSampleRate,
  replaysSessionSampleRate: config.sentry.replaysSessionSampleRate,
  replaysOnErrorSampleRate: config.sentry.replaysOnErrorSampleRate,
});
Vue.use(PicflowSentryPlugin);

// Make BootstrapVue available throughout your project
Vue.use(BootstrapVue);
// Optionally install the BootstrapVue icon components plugin
Vue.use(IconsPlugin);

Vue.use(
  new VueSocketIO({
    debug: true,
    connection: io(config.wsUrl, {
      withCredentials: true,
      extraHeaders: {
        ApiKey: config.wsApiKey,
      },
    }), //options object is Optional
  }),
);

Vue.config.productionTip = false;

if (process.env.NODE_ENV === 'production') {
  console.log = () => {};
  console.debug = () => {};
  console.info = () => {};
}

const vueApp = new Vue({
  template: '<App/>',
  render: (h) => h(App),
  components: { App },
  router,
  store,
});

checkStorage(vueApp);
