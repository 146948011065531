<template>
  <div v-if="pdfImages && pdfImages.length > 0" class="pdf-slider">
    <transition-group name="fade" tag="div" style="width: 100%; height: 100%; overflow: hidden">
      <div v-for="(image, index) in pdfImages" :key="`pdf_key_${index}`" class="pdf-slide">
        <img
          v-show="index === currentIndex"
          :src="image"
          :style="{ objectFit: imageMode, position: position }"
        />
      </div>
    </transition-group>
    <div class="prev" @click="prevPage(true)">
      <i class="material-icons-outlined align-middle icon-flipped logout">arrow_back_ios</i>
    </div>
    <div class="next" @click="nextPage(true)">
      <i class="material-icons-outlined align-middle icon-flipped logout">arrow_forward_ios</i>
    </div>
    <div class="pdf-page">
      {{ `${currentIndex + 1}/${pdfImages.length}` }}
    </div>
  </div>
  <div v-else class="no-data">No images</div>
</template>

<script>
  import { IMAGE_MODE } from '@/models/layoutDesigner';
  export default {
    name: 'PdfView',

    props: {
      pdfImages: {
        type: Array,
        default: () => [],
      },
      pageDuration: {
        type: Number,
        default: 15,
      },
      imageMode: {
        type: String,
        default: IMAGE_MODE.CONTAIN,
      },
      position: {
        type: String,
        default: 'relative',
      },
    },

    data() {
      return {
        timer: null,
        currentIndex: 0,
      };
    },

    mounted() {
      this.startSlide();
    },

    methods: {
      startSlide() {
        this.timer = setInterval(this.nextPage, this.pageDuration * 1000);
      },

      nextPage(reset = false) {
        if (reset) {
          clearInterval(this.timer);
          this.startSlide();
        }

        this.currentIndex =
          this.currentIndex < this.pdfImages.length - 1 ? this.currentIndex + 1 : 0;
      },
      prevPage() {
        this.currentIndex =
          this.currentIndex === 0 ? this.pdfImages.length - 1 : this.currentIndex - 1;
      },
    },
  };
</script>

<style lang="scss" scoped>
  .pdf-slider {
    width: 100%;
    height: 100%;
    position: relative;
    overflow: hidden;

    &:hover {
      .pdf-page,
      .next,
      .prev {
        opacity: 1;
      }
    }

    .pdf-slide {
      max-height: 100%;
    }
  }

  .pdf-page {
    position: absolute;
    justify-content: center;
    align-items: flex-end;
    right: min(5%, 16px);
    top: min(5%, 16px);
    color: #6a6b6a;
    font-size: 16px;
    opacity: 0;
  }

  .fade-enter-active,
  .fade-leave-active {
    transition: all 0.9s ease;
    overflow: hidden;
    visibility: visible;
    position: absolute;
    width: 100%;
    opacity: 1;
  }

  .fade-enter,
  .fade-leave-to {
    visibility: hidden;
    width: 100%;
    opacity: 0;
  }

  img {
    height: 100%;
    width: 100%;
  }

  .prev,
  .next {
    cursor: pointer;
    position: absolute;
    top: 40%;
    width: auto;
    padding: 8px;
    color: #6a6b6a;
    opacity: 0;
    font-weight: bold;
    font-size: 18px;
    transition: 0.7s ease;
    text-decoration: none;
    user-select: none;
    border-radius: 100%;
  }

  .next {
    right: min(5%, 16px);
  }

  .prev {
    left: min(5%, 16px);
  }

  .prev:hover,
  .next:hover {
    background-color: rgba(0, 0, 0, 0.4);
    color: white;
  }

  .no-data {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%;
  }
</style>