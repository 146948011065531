import {
  PLAYLIST_ITEM_REQUEST,
  PLAYLIST_ITEM_REQUEST_ERROR,
  PLAYLIST_ITEM_REQUEST_SUCCESS,
} from '../actions/playlist';
import { apiGetPlaylistItem, apiGetScreenPlaylistItem } from '../../api/playlist';
import Vue from 'vue';

const state = {
  status: '',
  playlists: [],
  playlistItems: [],
  playlistMetadata: {},
  playlistLayer: {},
};

const getters = {
  getCurrentPlaylist: (state) => (state.playlists.length === 1 ? state.playlists[0] : undefined),
  /*playlistLayouts: state => state.playlistLayouts.length > 0 ? state.playlistLayouts : [],*/
};

const actions = {
  [PLAYLIST_ITEM_REQUEST]: async ({ commit, rootState }, { playlist_id, params }) => {
    commit(PLAYLIST_ITEM_REQUEST);

    const screenId = rootState.player.screenId;

    try {
      const response = !!screenId
        ? await apiGetScreenPlaylistItem(playlist_id, screenId)
        : await apiGetPlaylistItem(playlist_id, params);

      const data = response.data;

      if (!data.items) throw new Error(data);

      let { items } = data;

      if (data.overridden_items) {
        items = data.overridden_items.map((overridden_item, index) => ({
          ...overridden_item,
          ...(overridden_item.override ? overridden_item.override.config : {}),
          item_priority:
            overridden_item.override?.config.item_priority ||
            overridden_item.item_priority ||
            index,
        }));
      }

      items = items.sort((item1, item2) => item1.item_priority - item2.item_priority);

      commit(PLAYLIST_ITEM_REQUEST_SUCCESS, { items, playlist: data.playlist });

      return { ...response, data: { ...data, overridden_items: items, items } };
    } catch (error) {
      console.log('error: ', error);
      commit(PLAYLIST_ITEM_REQUEST_ERROR, error);
    }
  },
};

const mutations = {
  [PLAYLIST_ITEM_REQUEST]: (state) => {
    state.playlistItemRequestStatus = 'loading';
  },
  [PLAYLIST_ITEM_REQUEST_SUCCESS]: (state, { items, playlist }) => {
    // "Fix" the priority so it works better with vue-draggable. The priority
    // is already handled server-side when the list is returned so we might
    // as well force it here in case some items have dupes.

    Vue.set(state, 'playlistItems', items);
    Vue.set(state, 'playlistMetadata', playlist);
    state.playlistItemRequestStatus = 'success';
  },

  [PLAYLIST_ITEM_REQUEST_ERROR]: (state, error) => {
    state.playlistItemRequestError = error.response.data.message;
    state.playlistItemRequestStatus = 'error';
  },
};

export default {
  state,
  getters,
  actions,
  mutations,
};
