<template>
  <WidgetNode
    :node="getWidgetNode(widget)"
    class="widget-item"
    :class="{ selected: isWidgetSelected }"
  />
</template>

<script>
  import { simpleTypeMixin } from '@/helpers';
  import WidgetNode from '@/components/widgets/templates/templateWidgets/WidgetNode.vue';
  import { WIDGET_TYPES } from '@/models/layoutDesigner';

  export default {
    name: 'TemplateLayer',

    props: {
      widget: {
        type: Object,
        default: () => null,
      },
    },

    mixins: [simpleTypeMixin],

    components: {
      WidgetNode,
    },

    computed: {
      isWidgetSelected() {
        const widget = this.$store.state.player.selectedWidget;

        if (!widget) return;

        const selectedItemId =
          widget.itemType === WIDGET_TYPES.PLAYLIST
            ? widget.object.playlist_id
            : widget.object.item_id;
        const itemId =
          this.widget.itemType === WIDGET_TYPES.PLAYLIST
            ? this.widget.object.playlist_id
            : this.widget.object.item_id;
        const isSelectedWidget = itemId === selectedItemId;

        return isSelectedWidget;
      },
    },

    methods: {
      getWidgetNode(widget) {
        return {
          data: widget,
          id: widget.object.type || WIDGET_TYPES.PLAYLIST,
        };
      },
    },
  };
</script>

<style lang="scss" scoped>
  .selected {
    z-index: 1500 !important;
    background-color: rgb(255, 255, 255);
  }
</style>
