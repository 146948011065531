import config from '../config';
import http from '@/utils/http';
import axios from 'axios';

// PlaylistItem
export const apiGetPlaylistItem = (playlist_id, params = {}) =>
  new Promise((resolve, reject) => {
    const apiUrl = `${config.apiUrl}userapi/playlist/${playlist_id}/item`;
    return http
      .get(apiUrl, { params })
      .then((resp) => {
        resolve(resp);
      })
      .catch((error) => {
        reject(error);
      });
  });

export const apiGetScreenPlaylistItem = (playlistId, screenId) =>
  new Promise((resolve, reject) => {
    const apiUrl = `${config.apiUrl}userapi/screens/${screenId}/playlist/${playlistId}/children`;

    return axios
      .get(apiUrl)
      .then((resp) => {
        resolve(resp);
      })
      .catch((error) => {
        reject(error);
      });
  });
