export const DEFAULT_TRAFIKLAB_VIEWER_STYLE = Object.freeze({
  title: {
    fontType: 'Arial',
    fontColor: '#000000',
    fontSize: 16,
  },
  departures: {
    fontType: 'Arial',
    fontColor: '#000000',
    fontSize: 12,
  },
  background: {
    color: '#FFFFFF',
    transparency: 0,
  },
  line: {
    fontColor: '#FFFFFF',
    backgroundColor: '#E3003A',
  },
  evenDeparturesBackground: '#FFFFFF',
  oddDeparturesBackground: '#EAEAEA',
});
