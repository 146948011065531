<template>
  <div v-if="loading" class="loading">
    <img src="@/assets/loader.svg" alt="loading" />
  </div>

  <div v-else-if="error" class="weather-error">Unable to fetch weather data</div>

  <div v-else class="weather-container" :style="mainStyle">
    <div class="weather-app">
      <div
        class="weather-left"
        v-if="!app.visibility || app.visibility?.time || app.visibility?.date"
      >
        <div v-if="!app.visibility || app.visibility?.date" class="weather-date" :style="dateStyle">
          {{ currentDay }}
        </div>

        <div v-if="!app.visibility || app.visibility?.time" class="weather-time" :style="timeStyle">
          {{ currentTime }}<sup class="time-period" v-if="!is24Format">{{ clockTime }}</sup>
        </div>

        <div v-if="!app.visibility || app.visibility?.date" class="weather-date" :style="dateStyle">
          {{ currentDate }}
        </div>
      </div>

      <div
        v-if="!app.visibility || app.visibility?.weather"
        class="weather-right"
        :style="rightStyle"
      >
        <img class="weather-image" :src="currentWeatherImage" alt="icon" :style="imageStyle" />
        <div v-if="currentTemp" class="weather-temperature" :style="degreesStyle">
          {{ currentTemp }}<sup>&deg;</sup>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
  import moment from 'moment-timezone';
  import config from '@/config';
  const timezone = config.timezone;

  import { apiWeatherCurrentConditions } from '@/api/weather';
  import { getRBGAColor } from '@/helpers/utils';

  export default {
    name: 'WeatherViewOld',

    props: {
      app: {
        type: Object,
      },
    },

    data() {
      return {
        loading: true,
        error: false,
        appData: null,
        weatherData: null,
        currentConditions: null,
        defaultStyle: {
          logoSize: 60,
          lineWidth: 1,
          lineColor: '#F0F0F0',
          alignment: 'center',
          textSizeLeft1: 14,
          textSizeLeft2: 30,
          textSizeRight: 32,
        },
        timer: null,
        fetcherTimer: null,
        clockObj: null,
      };
    },

    filters: {
      round(num) {
        return parseInt(num).toFixed(0);
      },
    },

    computed: {
      background() {
        return this.app && this.app && this.app.background
          ? this.app.background
          : this.defaultStyle;
      },

      measurementSystem() {
        if (!this.app) return 'Imperial';
        if (this.app.temp_unit === 'F') {
          return 'Imperial';
        }
        return 'Metric';
      },

      currentDay() {
        return moment(this.clockObj).format('dddd');
      },

      currentTime() {
        const format = this.is24Format ? 'HH:mm' : 'hh:mm';

        return moment(this.clockObj).format(format);
      },

      clockTime() {
        return moment(this.clockObj).format('a');
      },

      is24Format() {
        return this.app && parseInt(this.app.time_format) === 24;
      },

      currentDate() {
        return moment(this.clockObj).format('L');
      },

      currentTemp() {
        const temperature = this.currentConditions?.Temperature[this.measurementSystem].Value;

        if (!temperature) return '';

        return Math.floor(temperature);
      },

      currentFeelsLikeTemp() {
        if (!this.currentConditions || !this.currentConditions.RealFeelTemperature) return;

        return this.currentConditions.RealFeelTemperature[this.measurementSystem].Value;
      },

      currentWeatherText() {
        if (!this.currentConditions) return;

        return this.currentConditions.WeatherText;
      },

      currentWeatherImage() {
        const imageCode =
          this.currentConditions && this.currentConditions.WeatherIcon
            ? this.currentConditions.WeatherIcon
            : 1;

        return require(`@/assets/icon/weather/icon-${imageCode}.svg`);
      },

      dateStyle() {
        return {
          fontSize: `${this.background.textSizeLeft1 || 14}px`,
          lineHeight: `${this.background.textSizeLeft1 * 1.5 || 21}px`,
        };
      },

      timeStyle() {
        return {
          fontSize: `${this.background.textSizeLeft2 || 30}px`,
          lineHeight: `${this.background.textSizeLeft2 * 1.5 || 45}px`,
        };
      },

      mainStyle() {
        const opacity = this.background.transparency / 100;

        const backgroundColor = getRBGAColor(this.background.color, opacity);

        return {
          backgroundColor,
          justifyContent: this.background.alignment || 'center',
        };
      },

      rightStyle() {
        let style = {};

        if (!this.app.visibility || this.app.visibility.time || this.app.visibility.date) {
          style = {
            borderLeft: `${this.background.lineWidth || 1}px solid ${
              this.background.lineColor || '#F0F0F0'
            }`,
          };
        }

        return style;
      },

      degreesStyle() {
        return {
          fontSize: `${this.background.textSizeRight || 32}px`,
        };
      },

      imageStyle() {
        return {
          width: `${this.background.logoSize || 60}px`,
          height: `${this.background.logoSize || 60}px`,
        };
      },
    },

    methods: {
      initWeatherView() {
        const self = this;
        this.loading = true;
        this.error = false;
        this.fetchData()
          .then(() => {
            self.loading = false;
          })
          .catch((error) => {
            console.log('error: ', error);
            self.loading = false;
            self.error = true;
          });
      },

      async fetchData() {
        const appConfig = this.app;

        const currentConditions = await apiWeatherCurrentConditions(
          appConfig.location.join(','),
          appConfig.lang,
        );

        [this.currentConditions] = currentConditions;
      },

      weatherImageFromCode(code) {
        const fileName = String(code).padStart(2, '0');
        return `https://developer.accuweather.com/sites/default/files/${fileName}-s.png`;
      },

      isDayTime(timezone, sunrise, sunset) {
        const now = moment().tz(timezone);
        const sunriseMoment = moment(sunrise);
        const sunsetMoment = moment(sunset);
        return now.isBetween(sunriseMoment, sunsetMoment);
      },

      updateClock() {
        this.clockObj = moment();
        this.clockObj.tz(timezone);
      },

      clockTick() {
        this.updateClock();
      },
    },

    mounted() {
      this.initWeatherView();
      this.updateClock();
      this.loading = false;

      if (this.timer) {
        clearInterval(this.timer);
      }

      this.timer = setInterval(this.clockTick, 1000);
      this.fetcherTimer = setInterval(this.fetchData, 10 * 60 * 1000);
    },
  };
</script>

<style lang="scss" scoped>
  .loading,
  .weather-error {
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-grow: 1;

    img {
      width: 40px;
      height: 40px;
    }
  }

  .weather-container {
    display: flex;
    justify-content: center;
    height: 100%;
    align-items: center;
    padding: 24px;
    font-family: 'Poppins';
    color: #151515;

    .weather-app {
      display: flex;
    }

    .weather-left {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-content: space-between;
      padding: 0 16px 0 4px;
      text-align: center;

      .weather-date {
        font-weight: 600;
        color: #e3003a;
        text-transform: uppercase;
        letter-spacing: 2px;
        word-spacing: 2px;
        font-size: 14px;
      }

      .weather-time {
        font-weight: 275;
        font-size: 2.5em;
      }
    }

    .weather-right {
      display: flex;
      gap: max(20px, 5%);
      align-items: center;
      padding-right: min(16px, 5%);
      padding-left: min(16px, 5%);
      margin: 10px 0;

      .weather-image {
        width: min(50%, 100px);
      }

      .weather-temperature {
        font-weight: 600;
        font-size: 2.63em;
      }
    }
  }
</style>
