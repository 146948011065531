import Vue from 'vue';
import VueRouter from 'vue-router';

Vue.use(VueRouter);

// Routed components
import Player from '@/components/player/Index.vue';
import RegisterCode from '@/components/RegisterCode';
import NoLayout from '@/components/NoLayout';

import { isLoggedIn } from '@/services/auth';
import { SCREEN_LAYOUT_ENUM } from '@/constants';

const ifNotAuthenticated = (to, from, next) => {
  if (!isLoggedIn()) {
    next('/');
    return;
  }
  next();
};

const ifAuthenticated = (to, from, next) => {
  if (isLoggedIn() && localStorage.getItem(SCREEN_LAYOUT_ENUM)) {
    next(`/player/${localStorage.getItem(SCREEN_LAYOUT_ENUM)}`);
    return;
  } else if (isLoggedIn()) {
    next(`/no-layout`);
    return;
  }
  next();
};

var app_title = 'PicFlow';

const router = new VueRouter({
  routes: [
    {
      path: '/',
      component: RegisterCode,
      beforeEnter: ifAuthenticated,
      meta: {
        title: () => {
          return `${app_title}`;
        },
        layout: 'empty',
      },
    },
    {
      path: '/no-layout',
      component: NoLayout,
      beforeEnter: ifNotAuthenticated,
      meta: {
        title: () => {
          return `${app_title}`;
        },
        layout: 'empty',
      },
    },
    {
      path: '/player/:layout_id',
      component: Player,
      beforeEnter: ifNotAuthenticated,
      meta: {
        title: () => {
          return `${app_title}`;
        },
        layout: 'empty',
      },
    },
  ],
});

router.afterEach((to) => {
  Vue.nextTick(() => {
    document.title = to.meta.title(to);
  });
});

export default router;
