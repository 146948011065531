<template>
  <div class="web-player-v2">
    <div class="initializing-overlay text-center">
      <h1 class="header-text">Downloading assets. Please wait...</h1>
      <h6 class="content-text">The speed of the download depends on your internet connection.</h6>
      <Loader />
    </div>
  </div>
</template>

<script>
  import Loader from '@/components/common/Loader.vue';

  export default {
    name: 'DownloadingMediaAssetLoader',
    components: {
      Loader,
    },
  };
</script>

<style scoped lang="scss">
  .web-player-v2 {
    position: relative;
    width: 100%;
    height: 100vh;
    background-color: #000;
    color: #fff;
    overflow: hidden;

    ::v-deep .widget-item {
      position: absolute;
    }

    .highlight-overlay {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      background-color: rgba(41, 41, 41, 0.451);
      z-index: 1000;
    }

    .borders {
      position: absolute;
      border: 4px solid #e3003a;
      box-sizing: border-box;
      z-index: 2000;
    }
  }

  .initializing-overlay {
    height: 100vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }

  .header-text {
    color: #fff7bc;
    font-family: 'Poppins';
  }

  .loader {
    margin: 24px;
  }

  .content-text {
    color: #fff7bc;
    font-family: 'Poppins';
  }
</style>
