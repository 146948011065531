<template>
    <span class="loader"></span>
</template>
  
<script>
export default {
    name: "Loader",
}
</script>

<style lang="scss" scoped>
.loader {
    width: 16px;
    height: 16px;
    box-shadow: 0 30px, 0 -30px;
    border-radius: 4px;
    background: currentColor;
    display: block;
    margin: -50px auto 0;
    position: relative;
    color: #FFF;
    transform: translateY(30px);
    box-sizing: border-box;
    animation: animloader 2s ease infinite;
}

.loader::after,
.loader::before {
    content: '';
    box-sizing: border-box;
    width: 16px;
    height: 16px;
    box-shadow: 0 30px, 0 -30px;
    border-radius: 4px;
    background: currentColor;
    color: rgb(255, 247, 188);
    position: absolute;
    left: 30px;
    top: 0;
    animation: animloader 2s 0.2s ease infinite;
}

.loader::before {
    animation-delay: 0.4s;
    left: 60px;
}

@keyframes animloader {
    0% {
        top: 0;
        color: rgb(255, 247, 188);
    }

    50% {
        top: 30px;
        color: rgba(255, 247, 188, .2);
    }

    100% {
        top: 0;
        color: rgb(255, 247, 188);
    }
}</style>
  