export async function preLoadFont(fontUrl: string, fontFormat: string): Promise<void> {
  return new Promise((resolve, reject) => {
    const link = document.createElement('link');
    link.rel = 'preload';
    link.href = fontUrl;
    link.as = 'font';
    link.type = `font/${fontFormat}`;
    link.crossOrigin = 'anonymous';

    link.onload = () => resolve();
    link.onerror = () => reject(new Error(`Failed to preload font: ${fontUrl}`));

    document.head.appendChild(link);
  });
}
