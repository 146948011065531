import config from '../config';
import axios from 'axios';

axios.defaults.headers.common['Authorization'] = localStorage.getItem('user-session_token');

export function apiGetTemplate(id) {
  const endpoint = `${config.apiUrl}userapi/templates/${id}`;
  return axios.get(endpoint);
}

// ---- TEMPLATE CHILDREN ---- //

export function apiGetTemplateChildren(templateId) {
  const endpoint = `${config.apiUrl}userapi/templates/${templateId}/children`;
  return axios.get(endpoint);
}

export const apiGetScreenTemplateChildren = async (templateId, screenId, playlistId) => {
  return axios.get(
    `${config.apiUrl}userapi/screens/${screenId}/template/${templateId}/children${
      playlistId ? `?playlist_id=${playlistId}` : ''
    }`,
  );
};
