import * as Sentry from '@sentry/vue';
import { SESSION_TOKEN_ENUM } from '@/constants';
import { SENTRY_TAGS } from '@/constants/sentry';
import { jwtDecode } from 'jwt-decode';

export function getSessionTokenPayload() {
  let screenId = null;
  let organizationId = null;

  const sessionToken = localStorage.getItem(SESSION_TOKEN_ENUM);
  if (sessionToken !== null && sessionToken.length !== 0) {
    const payload = jwtDecode(sessionToken);
    screenId = payload?.screen_id;
    organizationId = payload?.organisation_id;
  }

  return {
    screenId,
    organizationId,
  };
}

/**
 * @param {{screenId: number | null, organizationId: string | null}} data
 */
export function initSentryTags(data) {
  Sentry.setTag(SENTRY_TAGS.ScreenId, data?.screenId);
  Sentry.setTag(SENTRY_TAGS.OrganizationId, data?.organizationId);
}

export async function startSessionReplay() {
  const replay = Sentry.getClient().getIntegrationById('Replay');

  try {
    replay.start();
  } catch (error) {
    // we want to stop existing session replay before starting a new one
    await replay.stop();
    replay.start();
  }
}

export async function stopSessionReplay() {
  const replay = Sentry.getClient().getIntegrationById('Replay');

  try {
    await replay.stop();
  } catch (error) {
    console.error(error);
    await Sentry.flush();
  }
}
