<script>
  export default {
    name: 'ErrorState',
    props: {
      title: {
        type: String,
        default: 'Failed to load widget',
      },
      content: {
        type: String,
        default: 'An error has occurred.',
      },
    },
  };
</script>

<template>
  <div class="container">
    <img class="icon" src="@/assets/icon/warning/warning.svg" alt="warning" />
    <div class="text-h6">{{ title }}</div>
    <div class="text-body2">{{ content }}</div>
  </div>
</template>

<style scoped lang="scss">
  .text-h6 {
    font-size: 1.25rem;
    font-weight: 500;
    line-height: 2rem;

    font-family: 'Poppins', serif;
    color: #151515;
  }

  .text-body2 {
    font-size: 0.875rem;
    font-weight: 400;
    line-height: 1.25rem;

    font-family: 'Poppins', serif;
    color: #333332;
  }

  .container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    text-align: center;
    padding: 12px;
    width: 100%;
    height: 100%;
    background: white;
  }

  .icon {
    width: 50px;
    height: 44px;
    margin-bottom: 12px;
  }
</style>
