import { render, staticRenderFns } from "./VideoWidget.vue?vue&type=template&id=6874dea7&scoped=true&"
import script from "./VideoWidget.vue?vue&type=script&lang=js&"
export * from "./VideoWidget.vue?vue&type=script&lang=js&"
import style0 from "./VideoWidget.vue?vue&type=style&index=0&id=6874dea7&prod&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "6874dea7",
  null
  
)

export default component.exports