export enum ButtonContentType {
  IMAGE = 'image',
  URL = 'url',
  VIDEO = 'video',
}

export enum ButtonTextWidthType {
  medium = 'normal',
  thin = 'lighter',
  bold = 'bold',
}

export enum ImageModeType {
  Origin = 'none',
  Contain = 'contain',
  Stretch = 'fill',
  Crop = 'cover',
}

export interface ButtonWidgetObject {
  type: string;
  name: string;

  // Image body
  imageAsButton: boolean;
  imageMode: ImageModeType.Crop;
  imageUrl: string;
  thumbnailUrl: string;

  // Normal body
  backgroundColor: string;
  captionText: string;
  textFont: string;
  fontWeight: ButtonTextWidthType;
  text: string;
  textColor: string;
  textSize: number;
  embossed: boolean;

  // Border
  displayBorder: boolean;
  borderRadius: number;
  borderWidth: number;
  borderColor: string;

  // Shadow
  shadowOpacity: number;
  shadowBlur: number;
  displayShadow: boolean;
  shadowColor: string;

  // modal content
  contentType: ButtonContentType;
  contentLink: string | null;
  contentThumbnailLink: string | null;
  idleTimeout: number;
  closeButton: boolean;
  width: number;
  height: number;
  x: number;
  y: number;
}
