import { SESSION_TOKEN_ENUM } from '@/constants';
import config from '../config';
import axios from 'axios';
import { Font } from '@/types/api/fonts';

axios.defaults.headers.common['Authorization'] = localStorage.getItem(SESSION_TOKEN_ENUM);

export async function apiGetFonts(): Promise<Font[]> {
  const response = await axios.get<Font[]>(`${config.apiUrl}userapi/fonts/`);
  return response.data;
}
