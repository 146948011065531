<template>
  <div>
    <img
      v-if="isWeb"
      :src="imageUrl"
      :style="imageStyle"
      :alt="slide.name"
      @error="handleImageError"
    />
    <template v-else>
      <img
        v-if="!isImageLoadError"
        :src="imageUrl"
        :style="imageStyle"
        :alt="slide.name"
        @error="handleImageError"
      />
      <img
        v-else
        :src="defaultImageUrl"
        :style="imageStyle"
        :alt="slide.name"
        @error="handleDefaultImageError"
      />
    </template>
  </div>
</template>

<script>
  import { simpleTypeMixin } from '@/helpers';
  import { getItemLocalUrl, getItemUrl } from '@/utils/mediaAsset';

  export default {
    name: 'ImageSlide',
    mixins: [simpleTypeMixin],

    props: {
      slide: {
        type: Object,
        default: () => ({}),
      },
    },

    data() {
      return { isImageLoadError: false };
    },

    computed: {
      imageStyle() {
        return {
          width: '100%',
          height: '100%',
          backgroundPosition: 'center',
          backgroundSize: 'auto',
          backgroundRepeat: 'no-repeat',
          objectFit: this.slide.imageMode,
        };
      },

      isWeb() {
        return window.isWeb;
      },

      imageUrl() {
        return getItemLocalUrl(this.slide.item_url, this.slide.item_id);
      },

      defaultImageUrl() {
        return getItemUrl(this.slide.item_url);
      },
    },

    methods: {
      handleImageError() {
        this.isImageLoadError = true;
        console.error(`Failed to load image: ${this.imageUrl}`);
      },

      handleDefaultImageError() {
        console.error(`Failed to load default image: ${this.defaultImageUrl}`);
      },
    },
  };
</script>
