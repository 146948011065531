import localforage from 'localforage';

import { SESSION_TOKEN_ENUM, REFRESH_TOKEN_ENUM, SCREEN_ID_ENUM } from '@/constants';

export const isLoggedIn = () => {
  return !!localStorage.getItem(SESSION_TOKEN_ENUM);
};

export const handleLogin = (res) => {
  try {
    localStorage.setItem(SESSION_TOKEN_ENUM, res.data['session_token']);
    localStorage.setItem(REFRESH_TOKEN_ENUM, res.data['refresh_token']);
    localStorage.setItem(SCREEN_ID_ENUM, res.data['screen_id']);

    localforage.setItem(SESSION_TOKEN_ENUM, res.data['session_token']);
    localforage.setItem(REFRESH_TOKEN_ENUM, res.data['refresh_token']);
    localforage.setItem(SCREEN_ID_ENUM, res.data['screen_id']);
  } catch (error) {
    console.log('Error saving token in storages', error);
  }
};
