import * as Sentry from '@sentry/vue';

export function serializedError(error) {
  return JSON.stringify(error, Object.getOwnPropertyNames(error));
}

export class SentryLogger {
  static error(message, error = null) {
    if (error) {
      console.error(message, error);
    } else {
      console.error(message);
    }

    Sentry.captureException(
      new Error(`${message} ${error === null ? '' : `: ${serializedError(error)}`}`),
    );
  }

  static warn(message, error = null) {
    if (error) {
      console.warn(message, error);
    } else {
      console.warn(message);
    }

    Sentry.captureMessage(
      `${message} ${error === null ? '' : `: ${serializedError(error)}`}`,
      'warning',
    );
  }

  static info(message, error = null) {
    if (error) {
      console.info(message, error);
    } else {
      console.info(message);
    }

    Sentry.captureMessage(
      `${message} ${error === null ? '' : `: ${serializedError(error)}`}`,
      'info',
    );
  }

  static log(message, error = null) {
    if (error) {
      console.log(message, error);
    } else {
      console.log(message);
    }

    Sentry.captureMessage(
      `${message} ${error === null ? '' : `: ${serializedError(error)}`}`,
      'log',
    );
  }
}
