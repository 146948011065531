import {
  PICFLOW_SCAP_API_FILES,
  PICFLOW_SCAP_API_PATH,
  PICFLOW_SUPPORTED_WEBOS_VERSION,
  PICFLOW_WEBOS_EXTERNAL_SCRIPT_PATH,
} from '@/constants/webOS';
import config from '@/config';

function loadScriptAsync(scriptUrl, id) {
  return new Promise((resolve, reject) => {
    var elem = document.getElementById(id);
    if (elem) {
      console.log(id + ' already exists.');
      //elem.remove();
      resolve();
      return;
    }

    var scriptElem = document.createElement('script');
    scriptElem.src = scriptUrl;
    scriptElem.id = id;
    scriptElem.async = false;

    scriptElem.onload = function (e) {
      console.log(e.target.src + ' is loaded.');
      resolve();
    };

    scriptElem.onerror = function () {
      reject(new Error('Failed to load script: ' + scriptUrl));
    };

    document.head.appendChild(scriptElem);
  });
}

async function getWebOSVersion() {
  return new Promise((resolve, reject) => {
    const successCallbackObject = (successObject) => {
      const webOSVersion = successObject.webOSVersion;

      if (isSupportedWebOSVersion(webOSVersion)) {
        resolve({ webOSVersion: webOSVersion });
      } else {
        console.error('Unsupported WebOS version: ' + webOSVersion);
        resolve({ webOSVersion: PICFLOW_SUPPORTED_WEBOS_VERSION['6.0'] });
      }
    };

    const failureCallbackObject = (cbObject) => {
      console.error('Failed to get WebOS version');
      console.error('[' + cbObject.errorCode + '] ' + cbObject.errorText);
      reject();
    };

    var custom = new Custom();
    custom.Signage.getwebOSVersion(successCallbackObject, failureCallbackObject);
  });
}

export function isSupportedWebOSVersion(webOSVersion) {
  switch (webOSVersion) {
    case PICFLOW_SUPPORTED_WEBOS_VERSION['3.0']:
    case PICFLOW_SUPPORTED_WEBOS_VERSION['3.2']:
    case PICFLOW_SUPPORTED_WEBOS_VERSION['4.0']:
    case PICFLOW_SUPPORTED_WEBOS_VERSION['4.1']:
    case PICFLOW_SUPPORTED_WEBOS_VERSION['6.0']:
      return true;
    default:
      return false;
  }
}

export async function loadScapApi(webOSVersion) {
  await loadScriptAsync(
    config.playerBaseUrl + PICFLOW_SCAP_API_PATH[webOSVersion] + PICFLOW_SCAP_API_FILES.DeviceInfo,
    'deviceInfoSCAPLib',
  );
  await loadScriptAsync(
    config.playerBaseUrl + PICFLOW_SCAP_API_PATH[webOSVersion] + PICFLOW_SCAP_API_FILES.Storage,
    'storageSCAPLib',
  );
  await loadScriptAsync(
    config.playerBaseUrl + PICFLOW_SCAP_API_PATH[webOSVersion] + PICFLOW_SCAP_API_FILES.Signage,
    'signageSCAPLib',
  );
}

export async function webOSAppInitialization() {
  console.info('Initialize player as WebOS app');

  // load Cordova and CustomJS library before initializing
  await loadScriptAsync(
    config.playerBaseUrl + PICFLOW_WEBOS_EXTERNAL_SCRIPT_PATH.CordovaLib,
    'cordovaLib',
  );

  await loadScriptAsync(
    config.playerBaseUrl + PICFLOW_WEBOS_EXTERNAL_SCRIPT_PATH.CustomLib,
    'customLib',
  );

  await loadScriptAsync(
    config.playerBaseUrl + PICFLOW_WEBOS_EXTERNAL_SCRIPT_PATH.WebOSLib,
    'webOSLib',
  );

  const { webOSVersion } = await getWebOSVersion();
  await loadScapApi(webOSVersion);

  await loadScriptAsync(
    config.playerBaseUrl + PICFLOW_WEBOS_EXTERNAL_SCRIPT_PATH.UtilsLib,
    'utilsLib',
  );

  await loadScriptAsync(
    config.playerBaseUrl + PICFLOW_WEBOS_EXTERNAL_SCRIPT_PATH.WebClientLib,
    'webClientLib',
  );

  return webOSVersion;
}
