import { SENTRY_EVENTS } from '@/constants/sentry';
import {
  getSessionTokenPayload,
  initSentryTags,
  startSessionReplay,
  stopSessionReplay,
} from '@/utils/sentry';
import config from '@/config';

const PicflowSentryPlugin = {
  install: function (Vue, options) {
    const stopSessionReplayAfterMinutes = config.sentry.stopSessionReplayAfterMinutes * 60 * 1000;
    let isSessionReplayActive = false;

    const handleStopSessionReplayEvent = async (_event) => {
      await stopSessionReplay();
      console.info(`Sentry session replay stoppedAt: ${new Date().toISOString()}`);

      isSessionReplayActive = false;
    };

    const handleStartSessionReplayEvent = async (_event) => {
      if (!isSessionReplayActive) {
        isSessionReplayActive = true;

        console.info(`Sentry session replay startedAt: ${new Date().toISOString()}`);
        await startSessionReplay();

        // we want to stop session replay after X minutes, so we don't capture to long
        setTimeout(async () => await handleStopSessionReplayEvent(), stopSessionReplayAfterMinutes);
      }
    };

    Vue.mixin({
      beforeMount() {
        const sessionTokenPayload = getSessionTokenPayload();
        initSentryTags(sessionTokenPayload);
      },
      async mounted() {
        window.addEventListener(SENTRY_EVENTS.StartSessionReplay, handleStartSessionReplayEvent);
      },
      async beforeDestroy() {
        window.removeEventListener(SENTRY_EVENTS.StartSessionReplay, handleStopSessionReplayEvent);
      },
    });
  },
  dispatchStartSessionReplayEvent: function () {
    const customEvent = new CustomEvent(SENTRY_EVENTS.StartSessionReplay);
    window.dispatchEvent(customEvent);
  },
};

export default PicflowSentryPlugin;
