import config from '../config';
import http from '@/utils/http';

export const apiWeatherCurrentConditions = async (location, language = 'en', type) => {
  const url = new URL('userapi/app/weather/get-weather', config.apiUrl);
  return new Promise((resolve, reject) => {
    http
      .post(url.toString(), { location, language, type })
      .then((res) => resolve(res.data))
      .catch((err) => reject(err.response));
  });
};
