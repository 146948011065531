import axios from 'axios';
import localforage from 'localforage';

import { SESSION_TOKEN_ENUM } from '@/constants';
import { getDeviceSpecificParam } from '@/helpers/utils';
import { FileSynchronizationManager } from '@/utils/internalFileStorage/fileSynchronizationManager';

axios.interceptors.request.use(function (config) {
  const token = localStorage.getItem(SESSION_TOKEN_ENUM);
  config.headers.Authorization = token;

  return config;
});

axios.interceptors.response.use(
  (response) => {
    return response;
  },
  async (error) => {
    if (401 === error.response.status) {
      localStorage.clear();
      localforage.clear();

      if (window.isWebOS === true) {
        await FileSynchronizationManager.removeAllInternalFiles();
      } else if (window.isAndroid === true) {
        window.logout();
      }

      window.location.href = `${window.location.origin}?${getDeviceSpecificParam()}`;
    }
    return Promise.reject(error);
  },
);

export default axios;
