<template>
  <WidgetNode
    :node="getWidgetNode(widget)"
    class="widget-item"
    :class="{ selected: selectedLayerId && selectedLayerId() === widget.assoc_id }"
  />
</template>

<script>
  import { simpleTypeMixin } from '@/helpers';
  import WidgetNode from '@/components/playerWidgets/WidgetNode.vue';
  import { WIDGET_TYPES } from '@/models/layoutDesigner';

  export default {
    inject: ['selectedLayerId'],
    props: {
      widget: {
        type: Object,
        default: () => null,
      },
    },

    mixins: [simpleTypeMixin],

    components: {
      WidgetNode,
    },

    methods: {
      getWidgetNode(widget) {
        return {
          data: widget,
          id: widget.object.type || WIDGET_TYPES.PLAYLIST,
        };
      },
    },
  };
</script>

<style lang="scss" scoped>
  .selected {
    z-index: 1500 !important;
  }
</style>