<template>
  <div
    @click.prevent="showImage"
    :class="['widget-item button-widget ', { 'auto-fit-text': widget.object.maximalTextSize }]"
    :id="widget.object.wid"
    :style="style"
  >
    <span :class="['button-text']">{{ widget.object.text }}</span>
  </div>
</template>

<script>
  import playerMixins from '@/models/player';
  import { simpleTypeMixin } from '@/helpers';
  import { getRBGAColor } from '@/helpers/utils';

  export default {
    name: 'ButtonWidget',

    mixins: [playerMixins, simpleTypeMixin],

    props: {
      widget: {
        type: Object,
        required: true,
      },
    },

    methods: {
      showImage() {
        this.$emit('show-enlarged', this.widget);
      },
    },

    computed: {
      style() {
        let newStyles = Object.assign(this.defaultStyling(this.widget), {
          overflow: 'hidden',
          backgroundColor: getRBGAColor(
            this.widget.object.buttonColor,
            Number(this.widget.object.buttonOpacity) / 100,
          ),
          color: getRBGAColor(
            this.widget.object.textColor,
            Number(this.widget.object.opacity) / 100,
          ),
          cursor: 'pointer',
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          fontWeight: 'bold',
          position: 'absolute',
          fontSize: `${this.widget.object.textSize}px`,
          borderRadius: `${this.widget.object.borderRadius}px`,
        });

        if (this.widget.object.loadImage) {
          newStyles = Object.assign(newStyles, {
            backgroundImage: `url("${this.widget.object.imageUrl}")`,
            backgroundSize: '100% 100%',
            backgroundRepeat: 'no-repeat',
            backgroundPosition: 'center',
          });
        }

        if (!this.widget.object.maximalTextSize) {
          newStyles['fontSize'] = `${this.widget.object.textSize}%`;
        }

        return newStyles;
      },
    },
  };
</script>

<style scoped></style>
