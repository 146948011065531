<template>
  <div
    ref="TableAppWrapper"
    class="table-wrapper"
    :class="{
      black: settings.styles.table.theme === 'black-white',
    }"
    :style="tableStyle"
  >
    <div ref="TableApp" class="rows-container">
      <div v-for="(row, index) in pageRows" :key="index" class="table-row" :style="rowStyle(row)">
        <div
          v-for="(cell, indexCol) in row.data"
          :key="indexCol"
          class="table-col"
          :style="{
            justifyContent: settings.styles[row.type].alignment || 'center',
          }"
        >
          {{ cell.content }}
        </div>
      </div>
    </div>
    <div class="table-pagination" v-if="pagesInTotal > 1">
      <ul>
        <li
          v-for="pageIndex in pagesInTotal"
          :key="pageIndex"
          :class="{ active: pageIndex === currentPage }"
        ></li>
      </ul>
    </div>
  </div>
</template>

<script>
  import { getRBGAColor } from '@/helpers/utils';

  export default {
    name: 'TableAppWebView',

    props: {
      settings: {
        type: Object,
      },
      size: {
        type: Object,
        default: () => null,
      },
      is_visible: {
        type: Boolean,
        default: false,
      },
    },
    data() {
      return {
        currentPage: 0,
        rowsContainerHeight: '100%',
        timer: null,
      };
    },

    async mounted() {
      this.$nextTick(this.resetPages);
    },

    async beforeDestroy() {
      this.destroyTimer();
    },

    watch: {
      size() {
        this.resetPages();
      },
      pagesInTotal() {
        this.resetPages();
      },
    },

    computed: {
      pagesInTotal() {
        return Math.ceil(
          (this.settings.rows.length || 1) / this.settings.styles.pagination.per_page,
        );
      },

      pageRows() {
        const allRows = this.settings.rows || [];
        const pageStartIndex = (this.currentPage - 1) * this.settings.styles.pagination.per_page;
        const pageEndIndex = this.currentPage * this.settings.styles.pagination.per_page;

        return allRows.slice(pageStartIndex, pageEndIndex);
      },

      tableStyle() {
        return {
          backgroundColor: getRBGAColor(this.settings.styles.table.backgroundColor),
        };
      },
    },

    methods: {
      rowStyle(rowData) {
        const style = this.settings.styles;

        return {
          height: this.rowsContainerHeight,
          backgroundColor:
            rowData.type === 'heading'
              ? getRBGAColor(style.heading.backgroundColor)
              : getRBGAColor(style.body.backgroundColor),
          fontSize:
            rowData.type === 'heading' ? `${style.heading.fontSize}px` : `${style.body.fontSize}px`,
          fontWeight: rowData.type === 'heading' ? 'bold' : 'normal',
          borderBottomColor: getRBGAColor(style.table.linesColor),
          borderBottomWidth: `${style.table.lineWidth || 1}px`,
        };
      },

      updateCurrentPage() {
        this.currentPage++;
        if (this.currentPage > this.pagesInTotal) {
          this.currentPage = 1;
        }
      },

      async resetPages() {
        this.destroyTimer();

        this.currentPage = 1;
        const totalRows =
          this.settings.styles.pagination.per_page <= this.settings.rows.length
            ? this.settings.styles.pagination.per_page
            : this.settings.rows.length;

        this.rowsContainerHeight = `${this.$refs.TableApp.clientHeight / totalRows}px`;

        this.startSlider();
      },

      startSlider() {
        if (this.pagesInTotal === 1) return;

        this.initTimer();
      },

      initTimer() {
        this.timer = setTimeout(this.nextSlide, this.settings.styles.pagination.interval * 1000);
      },

      destroyTimer() {
        clearTimeout(this.timer);
      },

      nextSlide() {
        this.currentPage = this.currentPage < this.pagesInTotal ? this.currentPage + 1 : 1;
        this.initTimer();
      },
    },
  };
</script>

<style lang="scss" scoped>
  .table-wrapper {
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 100%;
    flex-grow: 1;
    background-color: #2d2d2d;

    .rows-container {
      display: flex;
      flex-direction: column;
      overflow: hidden;
      flex-grow: 1;

      .table-row {
        display: flex;
        width: 100%;
        background: #5a5a5b;
        color: #e5e5e6;
        border-bottom: 1px solid #2d2d2d;
        font-size: 20px;

        .table-col {
          display: flex;
          align-items: center;
          padding: 0 max(8px, 2.5%);
          width: 50%;
        }
      }
    }

    .table-pagination {
      height: 30px;
      padding-top: 5px;

      ul {
        list-style: none;
        margin: 0;
        padding: 0;
        display: flex;
        justify-content: center;

        li {
          background: #2d2d2d;
          width: 20px;
          height: 20px;
          border-radius: 20px;
          border: 1px solid #5a5a5b;
          margin-right: 5px;
          margin-left: 5px;

          &.active {
            background: #5a5a5b;
          }
        }
      }
    }

    &.black {
      background-color: white;

      .table-row {
        background: white;
        color: #151515;
        border-bottom: 1px solid #000;
      }

      .table-pagination {
        ul {
          justify-content: center;

          li {
            background: #fff;
            border: 1px solid #b8b8b8;

            &.active {
              background: #b8b8b8;
            }
          }
        }
      }
    }
  }
</style>
