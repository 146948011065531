import localforage from 'localforage';

import {
  REFRESH_TOKEN_ENUM,
  SCREEN_ID_ENUM,
  SCREEN_LAYOUT_ENUM,
  SESSION_TOKEN_ENUM,
  SOCKET_ERROR_TYPES,
  SCREEN_ORIENTATION,
  PLAYER_ORIENTATION,
} from '@/constants';
import { getDeviceSpecificParam } from '@/helpers/utils';
import { handleLogin } from '@/services/auth';
import { handlePublish } from '@/services/screenInformation';
import { fetchScreenLayout } from '@/services/layout';
import { SET_PLAYER_ROTATION } from '@/store/actions/player';
import { SET_SCREEN_CODE, SET_LOADER } from '@/store/actions/login';
import { FileSynchronizationManager } from '@/utils/internalFileStorage/fileSynchronizationManager';

const socketMixin = {
  data() {
    return {
      loading: false,
      expiredTokenErrorCount: 0,
      joining: false,
    };
  },
  sockets: {
    connect: function () {
      console.log('socket connected from register code');
      this.$socket.emit('message', { data: "I'm connected!" });
    },
    message: function (msg) {
      console.log(msg);
    },
    // onAny: function (eventName, msg) {
    //   console.log("event name", eventName);
    //   console.log("Message", msg);
    // },
    validated_screen_code: async function (res) {
      handleLogin(res);
      const { session_token } = res.data;
      this.$socket.emit('join', {
        auth: session_token,
      });
    },
    //joined event need to add
    joined: async function (res) {
      if (this.joining) return;
      this.joining = true;
      const routePath = this.$router.history.current.path;
      //   if (res.type === "join_success") {
      //   }
      if (routePath.includes('player')) {
        this.joining = false;
      } else {
        const screenId = localStorage.getItem(SCREEN_ID_ENUM);
        const result = await fetchScreenLayout(screenId);
        this.loading = false;
        this.joining = false;
        if (result.success && result.data.status === 200) {
          localStorage.setItem(SCREEN_LAYOUT_ENUM, result.data.data.layout_id);
          localforage.setItem(SCREEN_LAYOUT_ENUM, result.data.data.layout_id);

          this.$router.push(`/player/${result.data.data.layout_id}`);
        } else if (result.success && result.data.status === 204) {
          localStorage.setItem(SCREEN_LAYOUT_ENUM, '');
          localforage.setItem(SCREEN_LAYOUT_ENUM, '');

          this.$bvToast.toast('No Layout available!', {
            title: 'Warning!',
            solid: true,
            variant: 'warning',
          });
          this.$router.push(`/no-layout`);
        } else {
          this.$bvToast.toast(result.message, {
            title: 'Error!',
            solid: true,
            variant: 'danger',
          });
          this.refreshToken();
        }
      }
    },
    device_info: function (res) {
      console.log('from device_info ===>', res);
    },
    update_device_info: function (res) {
      console.log('from update device_info ===>', res);
    },
    change_orientation: function (res) {
      const cb = () => {
        console.log('callback called from orientation');
        if (
          res.orientation === SCREEN_ORIENTATION.LANDSCAPE ||
          res.orientation === SCREEN_ORIENTATION.REVERSE_LANDSCAPE
        ) {
          this.$store.commit(SET_PLAYER_ROTATION, true);
        } else {
          this.$store.commit(SET_PLAYER_ROTATION, false);
        }
      };
      // Saved orientation information to force correct the orientation in android devices from App.vue on subsequent app restarts.
      localStorage.setItem(PLAYER_ORIENTATION, res.orientation);
      if (window.changeOrientation) {
        window.changeOrientation(res.orientation, cb);
      } else {
        cb();
      }
    },
    restart_player: async function (res) {
      await handlePublish(res.publish_id);
      const screenId = localStorage.getItem(SCREEN_ID_ENUM);
      const result = await fetchScreenLayout(screenId);
      if (result.success && result.data.status === 200) {
        localStorage.setItem(SCREEN_LAYOUT_ENUM, result.data.data.layout_id);
        localforage.setItem(SCREEN_LAYOUT_ENUM, result.data.data.layout_id);
      }
      window.location.href = `${window.location.origin}?${getDeviceSpecificParam()}`;
    },
    refreshed_auth_tokens: function (res) {
      this.initialRefreshTokenLoading = false;
      if (res.data) {
        localStorage.setItem(SESSION_TOKEN_ENUM, res.data['session_token']);
        localforage.setItem(SESSION_TOKEN_ENUM, res.data['session_token']);
        this.$socket.emit('join', {
          auth: res.data['session_token'],
        });
      }
    },
    error: function (err) {
      console.log('error ====>', err);
      console.log('error count ===>', this.expiredTokenErrorCount);
      switch (err.type) {
        case SOCKET_ERROR_TYPES.EXPIRED_TOKEN:
          if (this.expiredTokenErrorCount > 0) {
            // localStorage.clear();
            this.$bvToast.toast('Something went wrong! Please enter screen code again.', {
              title: 'Error!',
              solid: true,
              variant: 'danger',
            });
            // this.$router.push(`/`);
          } else {
            this.expiredTokenErrorCount = this.expiredTokenErrorCount + 1;
            this.refreshToken();
          }
          // code block
          break;
        default:
          this.loading = false;
          this.$store.commit(SET_LOADER, false);
          this.$bvToast.toast(err.msg, {
            title: 'Error!',
            solid: true,
            variant: 'danger',
          });
      }
    },
    logout: async function (res) {
      localStorage.clear();
      localforage.clear();

      if (window.isWebOS === true) {
        await FileSynchronizationManager.removeAllInternalFiles();
      } else if (window.isAndroid === true) {
        window.logout();
      }

      window.location.href = `${window.location.origin}?${getDeviceSpecificParam()}`;
    },

    code_generated: function (res) {
      this.$store.commit(SET_SCREEN_CODE, res.data);
      this.$store.commit(SET_LOADER, false);
      this.$bvToast.toast(
        'Code successfully generated. Please use the code in app.picler.se as soon as possible.',
        {
          title: 'Success!',
          solid: true,
          variant: 'success',
        },
      );
    },
  },
  methods: {
    refreshToken() {
      this.$socket.emit('refresh_auth_tokens', {
        refreshToken: localStorage.getItem(REFRESH_TOKEN_ENUM),
      });
    },
  },
};

export default socketMixin;
