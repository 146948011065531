import { saveScreenInformation } from '@/api/screenInformation';
import { PUBLISH_ID } from '@/constants';
import localforage from 'localforage';

export const handlePublish = async (publish_id) => {
  localStorage.setItem(PUBLISH_ID, publish_id);
  localforage.setItem(PUBLISH_ID, publish_id);
  try {
    await saveScreenInformation({
      publish_id_player: publish_id,
    });
  } catch (error) {
    console.log('Error saving screen information', error);
  }
};
