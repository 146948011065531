import { WEBOS_RELATIVE_PATH } from '@/constants/webOS';
import config from '@/config';

function replaceItemUrlWithFileName(path, newFileName) {
  const parts = path.split('/');
  const directory = parts[0];
  const fileName = parts[parts.length - 1];

  const extension = fileName.split('.').pop();

  return `${directory}/${newFileName}.${extension}`;
}

export function getItemLocalUrl(itemUrl, itemId) {
  if (window.isWebOS) {
    return `${WEBOS_RELATIVE_PATH}/${replaceItemUrlWithFileName(itemUrl, itemId)}`;
  }

  return `${config.baseUrl}/${itemUrl}`;
}

export function getItemUrl(itemUrl) {
  return `${config.baseUrl}/${itemUrl}`;
}
