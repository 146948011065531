<template>
  <div class="loading-placeholder">
    <div class="loading-icon" :style="styles"></div>
  </div>
</template>

<script>
  export default {
    name: 'Loader2',

    props: {
      size: {
        type: Number,
        default: 30,
      },
    },

    computed: {
      styles() {
        return `width: ${this.size}px; height:${this.size}px;`;
      },
    },
  };
</script>

<style scoped>
  .loading-placeholder {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%;
  }

  .loading-icon {
    border-radius: 50%;
    border: 3px solid #ccc;
    border-top-color: #333;
    animation: spin 1s ease-in-out infinite;
  }

  @keyframes spin {
    from {
      transform: rotate(0deg);
    }
    to {
      transform: rotate(360deg);
    }
  }
</style>
