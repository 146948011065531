import {
  WEBOS_DOWNLOAD_ACTION,
  WEBOS_DOWNLOAD_STATUS,
  WEBOS_URI_MAX_LENGTH,
} from '@/constants/webOS';

export function kiloByteToMB(kiloBytes) {
  return kiloBytes / 1024;
}

export function byteToMB(bytes) {
  return bytes / 1024 ** 2;
}

export default class WebOSStorageManager {
  /**
   * Handles file download actions.
   * @param {string} action - The action to perform. Can be 'start', 'pause', 'resume', or 'cancel'.
   * @param {string} [source] - The URI of the source file. Required if action is 'start'.
   * @param {string} [destination] - The URI of the destination file. Required if action is 'start'.
   * @param {Object} [httpOption] - HTTP/HTTPS options.
   * @param {number} [httpOption.maxRedirection] - The maximum number of redirects allowed.
   * @param {Object} [httpOption.headers] - The pair value ([name: value]) to be included in the HTTP request header.
   * @param {number} [httpOption.timeout] - The time (in milliseconds) before the underlying TCP connection time out.
   * @param {string} [httpOption.cacertDomain] - The domain name of the registered server certificate.
   * @param {boolean} [httpOption.insecure] - If true, allows connection to the HTTP site without SSL certificate validation.
   * @returns {Promise<Object>} A promise that resolves to an object containing:
   *   - object.ticket {string} - The download ticket.
   *   - object.source {string} - URI to the source file.
   *   - object.destination {String} - URI to the destination file. .
   * @throws {Error} If the path URI is longer than the maximum allowed length.
   */
  static downloadFile(action, source, destination, ticket = null) {
    if (destination.length > WEBOS_URI_MAX_LENGTH) {
      throw new Error(`'Destination URI is longer than ${WEBOS_URI_MAX_LENGTH}`);
    }

    return new Promise((resolve, reject) => {
      const successCallbackObject = (cbObject) => {
        resolve({ ticket: cbObject?.ticket, source: source, destination: destination });
      };

      const failureCallbackObject = (cbObject) => {
        reject(new Error(`Error Code [${cbObject.errorCode}]: ${cbObject.errorText}`));
      };

      const storage = new Storage();
      storage.downloadFile(successCallbackObject, failureCallbackObject, {
        action: action,
        source: source,
        destination: destination,
        ...(ticket !== null ? { ticket: ticket } : {}),
      });
    });
  }

  static getDownloadFileStatus(ticket, subscribe = false) {
    return new Promise((resolve, reject) => {
      const successCallbackObject = (cbObject) => {
        resolve({
          ticket: cbObject.ticket,
          status: cbObject.status,
          source: cbObject?.source,
          destination: cbObject?.destination,
          amountReceived: cbObject?.amountReceived,
          amountTotal: cbObject?.amountTotal,
          reason: cbObject?.reason,
        });
      };

      const failureCallbackObject = (cbObject) => {
        reject(new Error(`Error Code [${cbObject.errorCode}]: ${cbObject.errorText}`));
      };

      var storage = new Storage();
      storage.getDownloadFileStatus(successCallbackObject, failureCallbackObject, {
        ticket: ticket,
        subscribe: subscribe,
      });
    });
  }

  static removeFile(file, recursive = false) {
    if (file.length > WEBOS_URI_MAX_LENGTH) {
      throw new Error(`'File URI is longer than ${WEBOS_URI_MAX_LENGTH}`);
    }

    return new Promise((resolve, reject) => {
      const successCallbackObject = (cbObject) => {
        resolve();
      };

      const failureCallbackObject = (cbObject) => {
        reject(new Error(`Error Code [${cbObject.errorCode}]: ${cbObject.errorText}`));
      };

      var storage = new Storage();
      storage.removeFile(successCallbackObject, failureCallbackObject, {
        file: file,
        recursive: recursive,
      });
    });
  }

  static removeAll(device) {
    if (device !== 'internal') {
      throw new Error('Device value needs to be "internal"');
    }

    return new Promise((resolve, reject) => {
      const successCallbackObject = (cbObject) => {
        resolve();
      };

      const failureCallbackObject = (cbObject) => {
        reject(new Error(`Error Code [${cbObject.errorCode}]: ${cbObject.errorText}`));
      };

      var storage = new Storage();
      storage.removeAll(successCallbackObject, failureCallbackObject, {
        device: device,
      });
    });
  }

  static statFile(path) {
    if (path.length > WEBOS_URI_MAX_LENGTH) {
      throw new Error(`'Path URI is longer than ${WEBOS_URI_MAX_LENGTH}`);
    }

    return new Promise((resolve, reject) => {
      const successCallbackObject = (cbObject) => {
        resolve({
          type: cbObject.type,
          size: cbObject.size,
          accessedAt: cbObject.atime,
          modifiedAt: cbObject.mtime,
          ctime: cbObject.ctime,
        });
      };

      const failureCallbackObject = (cbObject) => {
        reject(new Error(`Error Code [${cbObject.errorCode}]: ${cbObject.errorText}`));
      };

      var storage = new Storage();
      storage.statFile(successCallbackObject, failureCallbackObject, {
        path: path,
      });
    });
  }

  static exists(path) {
    if (path.length > WEBOS_URI_MAX_LENGTH) {
      throw new Error(`'Path URI is longer than ${WEBOS_URI_MAX_LENGTH}`);
    }

    return new Promise((resolve, reject) => {
      const successCallbackObject = (cbObject) => {
        resolve({ exists: cbObject.exists });
      };

      const failureCallbackObject = (cbObject) => {
        reject(new Error(`Error Code [${cbObject.errorCode}]: ${cbObject.errorText}`));
      };

      var storage = new Storage();
      storage.exists(successCallbackObject, failureCallbackObject, {
        path: path,
      });
    });
  }

  static mkdir(path) {
    if (path.length > WEBOS_URI_MAX_LENGTH) {
      throw new Error(`'Path URI is longer than ${WEBOS_URI_MAX_LENGTH}`);
    }

    return new Promise((resolve, reject) => {
      const successCallbackObject = (cbObject) => {
        resolve();
      };

      const failureCallbackObject = (cbObject) => {
        reject(new Error(`Error Code [${cbObject.errorCode}]: ${cbObject.errorText}`));
      };

      var storage = new Storage();
      storage.mkdir(successCallbackObject, failureCallbackObject, {
        path: path,
      });
    });
  }

  /**
   * Lists the files in the specified path.
   * @param {string || null} [path='file://internal'] - The URI of the target path.
   * The default value is 'file://internal', which is the root directory of the local storage.
   * @returns {Promise<Object>} A promise that resolves to an array containing object:
   *   - object.name {string} - File name.
   *   - object.type {string} - File type.
   *   - object.size {number} - File size (Byte).
   * @throws {Error} If the path URI is longer than the maximum allowed length.
   */
  static listFiles(path) {
    if (path.length > WEBOS_URI_MAX_LENGTH) {
      throw new Error(`'Path URI is longer than ${WEBOS_URI_MAX_LENGTH}`);
    }

    return new Promise((resolve, reject) => {
      const successCallbackObject = (cbObject) => {
        var totalFileSizeInMB = 0;
        const files = cbObject.files.map((file) => {
          totalFileSizeInMB = totalFileSizeInMB + byteToMB(file.size);

          return {
            localName: file.name,
            localFileUrl: `${path}/${file.name}`,
            type: file.type,
            size: file.size,
          };
        });

        resolve({
          files: new Map(files.map((file) => [file.localFileUrl, file])),
          totalFileSizeInMB,
        });
      };

      const failureCallbackObject = (cbObject) => {
        reject(new Error(`Error Code [${cbObject.errorCode}]: ${cbObject.errorText}`));
      };

      var storage = new Storage();
      storage.listFiles(successCallbackObject, failureCallbackObject, {
        path: path,
      });
    });
  }

  static getStorageInfo() {
    return new Promise((resolve, reject) => {
      const successCallbackObject = (cbObject) => {
        resolve({
          freeInMB: kiloByteToMB(cbObject.free),
          totalInMB: kiloByteToMB(cbObject.total),
          usedInMB: kiloByteToMB(cbObject.used),
        });
      };

      const failureCallbackObject = (cbObject) => {
        reject(new Error(`Error Code [${cbObject.errorCode}]: ${cbObject.errorText}`));
      };

      var storage = new Storage();
      storage.getStorageInfo(successCallbackObject, failureCallbackObject);
    });
  }

  static async customFileDownload(source, destination, onData = null) {
    const downloadObject = await WebOSStorageManager.downloadFile(
      WEBOS_DOWNLOAD_ACTION.Start,
      source,
      destination,
    );
    var ticket = downloadObject.ticket;

    const sleep = (ms) => new Promise((resolve) => setTimeout(resolve, ms));

    async function checkStatus() {
      const downloadStatus = await WebOSStorageManager.getDownloadFileStatus(ticket, true);

      if (onData) {
        await onData({
          source: downloadStatus.source,
          destination: downloadStatus.destination,
          status: downloadStatus.status,
          amountReceived: downloadStatus.amountReceived,
          amountTotal: downloadStatus.amountTotal,
        });
      }

      if (downloadStatus.status === WEBOS_DOWNLOAD_STATUS.Completed) {
        return true;
      }
      if (downloadStatus.status === WEBOS_DOWNLOAD_STATUS.Paused) {
        const downloadObject = await WebOSStorageManager.downloadFile(
          WEBOS_DOWNLOAD_ACTION.Resume,
          source,
          destination,
          ticket,
        );

        ticket = downloadObject.ticket;
      } else if (
        downloadStatus.status === WEBOS_DOWNLOAD_STATUS.Canceled ||
        downloadStatus.status === WEBOS_DOWNLOAD_STATUS.Failed
      ) {
        throw new Error(
          JSON.stringify({
            source: downloadStatus.source,
            destination: downloadStatus.destination,
            status: downloadStatus.status,
            amountReceived: downloadStatus.amountReceived,
            amountTotal: downloadStatus.amountTotal,
          }),
        );
      }

      return false;
    }

    var done = false;
    do {
      await sleep(1000);
      done = await checkStatus();
    } while (!done);
  }

  static async listFilesWithMetadata(path) {
    if (path.length > WEBOS_URI_MAX_LENGTH) {
      throw new Error(`'Path URI is longer than ${WEBOS_URI_MAX_LENGTH}`);
    }

    const fileObject = await WebOSStorageManager.listFiles(path);

    for (let file of Array.from(fileObject.files.values())) {
      const fileStat = await WebOSStorageManager.statFile(file.localFileUrl);

      file['metadata'] = {
        accessedAt: fileStat.accessedAt,
        modifiedAt: fileStat.modifiedAt,
        ctime: fileStat.ctime,
      };
    }

    return fileObject;
  }

  static isTemporaryFile(path) {
    const parts = path.split('/');
    const tempFileName = parts[parts.length - 1];

    // the SCAP API Storage downloadFile will write to a private file as temp file while files are downloaded
    return tempFileName.startsWith('.');
  }
}
