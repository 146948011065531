<template>
  <div class="widget-item" :style="style">
    <PlaylistPlayer
      :style="{
        position: 'relative',
      }"
      :playlist="{ ...widget.object, imageMode: widget.position.imageMode }"
    />
  </div>
</template>

<script>
  import templateWidget from '@/components/widgets/templates/mixins/templateWidget';
  import { simpleTypeMixin } from '@/helpers';

  import PlaylistPlayer from '@/components/player/PlaylistPlayer.vue';

  export default {
    name: 'PlaylistWidget',

    mixins: [templateWidget, simpleTypeMixin],

    components: {
      PlaylistPlayer,
    },

    props: {
      widget: {
        type: Object,
        required: true,
      },
    },

    computed: {
      style() {
        const style = this.defaultStyling(this.widget);

        return style;
      },
    },
  };
</script>

<style scoped lang="scss">
  .widget-item {
    position: absolute;
  }
</style>
