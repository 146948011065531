import localforage from 'localforage';
import { FILE_SYNC_DOWNLOAD_STATUS } from '@/utils/internalFileStorage/fileSynchronizationManager';

export class FileDownloadHistoryStore {
  static getFileDownloadHistoryInstance() {
    return localforage.createInstance({
      name: 'FileDownloadHistoryDB',
      storeName: 'FileDownloadHistory',
    });
  }

  static async setItemOrUpdate(localFileUrl, data) {
    if (!localFileUrl) {
      return;
    }

    const item = await FileDownloadHistoryStore.getItem(localFileUrl);

    if (item === null) {
      const now = new Date().toISOString();

      await FileDownloadHistoryStore.setItem(localFileUrl, {
        ...data,
        retries: 0,
        localstorageCreatedAt: now,
        localstorageUpdatedAt: now,
      });
      return;
    }

    await FileDownloadHistoryStore.setItem(localFileUrl, {
      ...data,
      retries: data.status === FILE_SYNC_DOWNLOAD_STATUS.Success ? item.retries : item.retries + 1,
      localstorageCreatedAt: item.localstorageCreatedAt,
      localstorageUpdatedAt: new Date().toISOString(),
    });
  }

  static setItem(localFileUrl, data) {
    const store = FileDownloadHistoryStore.getFileDownloadHistoryInstance();
    return store.setItem(localFileUrl, data);
  }

  static getItem(localFileUrl) {
    const store = FileDownloadHistoryStore.getFileDownloadHistoryInstance();
    return store.getItem(localFileUrl);
  }

  static async removeItem(localFileUrl) {
    const store = FileDownloadHistoryStore.getFileDownloadHistoryInstance();

    try {
      await store.removeItem(localFileUrl);
    } catch (error) {
      // do nothing if item does not exist
    }
  }

  static async removeItems(localFileUrls) {
    for (const localFileUrl in localFileUrls) {
      await this.removeItem(localFileUrl);
    }
  }

  static async getKeys() {
    const store = FileDownloadHistoryStore.getFileDownloadHistoryInstance();
    return await store.keys();
  }

  static clear() {
    const store = FileDownloadHistoryStore.getFileDownloadHistoryInstance();
    return store.clear();
  }

  static async getStore() {
    const store = FileDownloadHistoryStore.getFileDownloadHistoryInstance();

    const allItems = [];

    await store.iterate((value, key) => {
      allItems.push(value);
    });

    return allItems;
  }
}
