import { screenLayoutLink } from "@/constants/routes";
import http from "@/utils/http";

export const fetchScreenLayout = async (screenId) => {
  try {
    let result = await http.get(screenLayoutLink(screenId));
    return {
      success: true,
      data: result,
    };
  } catch (e) {
    let message = "";
    if (e.response && e.response.data && e.response.data.message)
      message = e.response.data.message;
    else message = e.message;
    return {
      success: false,
      message,
    };
  }
};
