import config from '@/config';
import { getCloudfrontUrl } from '@/helpers/utils';

import { getRSSAppSettings } from '@/helpers/utils';

// https://stackoverflow.com/a/8260383/1662806
export const youtubeIdFromUrl = (url) => {
  const regExp = /^.*((youtu.be\/)|(v\/)|(\/u\/\w\/)|(embed\/)|(watch\?))\??v?=?([^#&?]*).*/;
  const match = url.match(regExp);
  return match && match[7].length === 11 ? match[7] : false;
};
export const getEncodedURL = (baseUrl, uri = null) => {
  if (!uri) {
    return encodeURI(baseUrl);
  }
  return `${baseUrl}/${encodeURI(uri)}`;
};

export const getSimpleType = (mimeType) => {
  let retType = 'image';

  switch (mimeType) {
    case 'image/jpeg':
      retType = 'image';
      break;
    case 'image/png':
      retType = 'image';
      break;
    case 'video/mp4':
      retType = 'video';
      break;
    case 'video/webm':
      retType = 'video';
      break;
    case 'youtube':
      retType = 'youtube';
      break;
    case 'app/rss':
      retType = 'rss';
      break;
    case 'app/table':
      retType = 'table';
      break;
    case 'app/pdf':
      retType = 'pdf';
      break;
    case 'application/pdf':
      retType = 'pdf';
      break;
    case 'app/trafiklab':
      retType = 'trafiklab';
      break;
    case 'app/template':
      retType = 'template';
      break;
    case 'app/local-template':
      retType = 'local-template';
      break;
    case 'app/weather':
      retType = 'weather';
      break;
    case 'layer':
      retType = 'layer';
      break;
    default:
      retType = 'unknown';
  }

  return retType;
};

export const getMimes = (type) => {
  let retType = [];

  switch (type) {
    case 'image':
    case 'images':
      retType = ['image/png', 'image/jpeg'];
      break;
    case 'video':
    case 'videos':
      retType = ['video/mp4', 'video/webm', 'youtube'];
      break;
    case 'app':
    case 'apps':
      retType = ['app/rss'];
      break;
    default:
      retType = [];
  }

  return retType;
};

export const getPlaylistItemName = (playlistItem) => {
  const itemType = getSimpleType(playlistItem.item_type);

  if (itemType === 'image' || itemType === 'video') {
    const temp = playlistItem.item_url.split('/');
    return temp[temp.length - 1];
  }

  if (itemType === 'rss' || itemType === 'table') {
    return playlistItem.app.name;
  }

  return playlistItem.item_url;
};

export const openUrl = (url) => {
  window.open(url, '_blank');
};

export const isCategory = (item) => !!item.category_id && !item.playlist_id;

export const isPlaylist = (item) => !!item.playlist_id;

// Return a simple string like 'image' or 'video' for various content types
export const globalMethodsMixin = {
  data() {
    return {
      baseUrl: config.baseUrl,
    };
  },

  methods: {
    youtubeIdFromUrl,
    getSimpleType,
    getMimes,
    getEncodedURL,
    getPlaylistItemName,
    openUrl,
    isCategory,
    isPlaylist,
    getRSSAppSettings,
  },
};

export const simpleTypeMixin = globalMethodsMixin;
