<script lang="ts">
  import { defineComponent } from 'vue';

  export default defineComponent({
    name: 'VideoSlide',
  });
</script>

<script setup lang="ts">
  import { computed, onMounted, ref, toRefs, watch } from 'vue';
  import { getItemLocalUrl, getItemUrl } from '@/utils/mediaAsset';

  const props = withDefaults(
    defineProps<{
      show?: boolean;
      restartSignal?: string | null;
      imageMode?: 'fill' | 'contain' | 'cover' | 'none' | 'scale-down';
      itemUrl: string;
      itemId: string;
      itemType: string;
    }>(),
    {
      show: false,
      restartSignal: null,
      imageMode: 'cover',
    },
  );
  const { show, restartSignal, imageMode, itemUrl, itemId, itemType } = toRefs(props);

  const videoRef = ref<HTMLVideoElement | null>(null);
  const isPlaying = ref(false);

  const videoUrl = computed(() => getItemLocalUrl(itemUrl.value, itemId.value));
  const defaultVideoUrl = computed(() => getItemUrl(itemUrl.value));

  const isWeb = computed(() => {
    return window.isWeb as boolean;
  });

  watch([show, restartSignal], ([newShow, newRestartSignal], [_oldShow, oldRestartSignal]) => {
    if (videoRef.value === null) return;

    if (newShow && newRestartSignal !== oldRestartSignal && newRestartSignal !== null) {
      resetVideo(false);
      isPlaying.value = true;
    }
  });

  watch(show, (newShow, oldShow) => {
    if (videoRef.value === null) return;

    // handle visible to hidden
    if (oldShow === true && newShow === false) {
      resetVideo();
      isPlaying.value = false;
    }
  });

  onMounted(() => {
    if (show.value) {
      playVideo();
    }
  });

  function resetVideo(pause = true) {
    if (videoRef.value === null) return;

    videoRef.value.pause();
    videoRef.value.currentTime = 0;

    if (!pause) {
      playVideo();
    }
  }

  function playVideo() {
    if (videoRef.value === null) return;

    videoRef.value.currentTime = 0;
    const playPromise = videoRef.value.play();

    // browsers released before 2019 may not return a value
    if (playPromise !== undefined) {
      playPromise.then((_) => {}).catch((_error) => {});
    }
  }

  function handleVideoError() {
    console.error(`Failed to load: ${videoUrl.value}`);
  }

  function handleDefaultVideoError() {
    console.error(`Failed to load default: ${defaultVideoUrl.value}`);
  }
</script>

<template>
  <video
    loop
    muted
    texture
    ref="videoRef"
    class="playlist-slide-video"
    :style="{
      objectFit: imageMode,
      visibility: show ? 'visible' : 'hidden',
    }"
  >
    <source :src="videoUrl" :type="itemType" @error="handleVideoError" />
    <source
      v-if="!isWeb"
      :src="defaultVideoUrl"
      :type="itemType"
      @error="handleDefaultVideoError"
    />
  </video>
</template>

<style scoped lang="scss">
  .playlist-slide-video {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;

    &.stretch {
      object-fit: fill;
    }

    &.origin {
      object-fit: none;
    }
  }
</style>
