import { SET_SCREEN_CODE, SET_LOADER } from '@/store/actions/login';

const state = {
  generatedCode: null,
  loading: false,
};

const getters = {
  getGeneratedCode: (state) => {
    return state.generatedCode;
  },
  getIsLoading: (state) => {
    return state.loading;
  },
};

const mutations = {
  [SET_SCREEN_CODE]: (state, code) => {
    state.generatedCode = code;
  },
  [SET_LOADER]: (state, isLoading) => {
    state.loading = isLoading;
  },
};

export default {
  getters,
  state,
  mutations,
};
