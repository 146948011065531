export const SESSION_TOKEN_ENUM = 'sessionToken';
export const REFRESH_TOKEN_ENUM = 'refreshToken';
export const SCREEN_ID_ENUM = 'screenId';
export const SCREEN_LAYOUT_ENUM = 'screenLayout';
export const PUBLISH_ID = 'publishId';
export const PLAYER_ORIENTATION = 'playerOrientation';

export const CONTENT_TYPE_WEATHER_APP = 'app/weather';

export const SOCKET_ERROR_TYPES = {
  EXPIRED_TOKEN: 'expired_token',
};

export const REFRESH_LAYOUT_STATUS = {
  SELECTING: 'SELECTING',
  RESIZING: 'RESIZING',
  MOVING: 'MOVING',
  STAND_BY: 'STAND_BY',
  UPDATING: 'UPDATING',
};

export const SCREEN_ORIENTATION = {
  LANDSCAPE: 'landscape',
  PORTRAIT: 'portrait',
  REVERSE_LANDSCAPE: '180d',
  REVERSE_PORTRAIT: '270d',
};

export const DAYS_OF_THE_WEEK = [
  'monday',
  'tuesday',
  'wednesday',
  'thursday',
  'friday',
  'saturday',
  'sunday',
];

export const DISPLAY_PRIORITY = {
  PRIORITY: 'priority',
  STANDARD: 'standard',
};
