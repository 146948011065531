import _ from 'lodash';
import Vue from 'vue';

import { LAYOUT_DESIGNER_SET_LAYOUT } from '@/store/actions/layoutDesigner';

import { LAYOUT_ASPECT_RATIOS, LAYOUT_RESOLUTIONS, WIDGET_TYPES } from '@/models/layoutDesigner';

import { SET_LAYOUT_DIMENSIONS } from '@/store/actions/layoutDesigner';

import { REFRESH_LAYOUT_STATUS } from '@/constants';

const state = {
  // shared
  widgets: {},
  isWidgetSettingTab: true,

  gridIsOn: false,

  // for editor
  selectedWidget: null,
  layout: null,

  // layouts page
  layouts: [],

  layoutDimensions: {
    width: 1200,
    height: 680,
  },

  isLayoutHorizontal: true,
  refreshingLayoutStatus: REFRESH_LAYOUT_STATUS.HOLD,
};

const getters = {
  getLayoutRotation: (state) => (state.isLayoutHorizontal ? 'horizontal' : 'vertical'),

  getResolution: (state) => {
    const aspectRatio =
      (state.layout && state.layout.settings.aspectRatio) || LAYOUT_ASPECT_RATIOS.SIXTEEN_NINE;
    const resolution =
      (state.layout && state.layout.settings.resolution) ||
      LAYOUT_RESOLUTIONS.defaultValues[aspectRatio];

    return LAYOUT_RESOLUTIONS.resolutions[aspectRatio][resolution];
  },

  usedWidgets: (state, getters) => {
    return getters.widgetsArray.map((widget) => ({
      itemId: widget.assoc_id,
      label: `${_.capitalize(widget.object.item_type || WIDGET_TYPES.PLAYLIST)}: ${
        widget.object.name
      }`,
    }));
  },

  wholeScreenButton: (state) => {
    return Object.values(state.widgets)
      .filter((w) => w.type === WIDGET_TYPES.BUTTON)
      .find((w) => w.wholeScreen);
  },
};

const actions = {};

const mutations = {
  [SET_LAYOUT_DIMENSIONS]: (state, dimensions) => {
    state.layoutDimensions = dimensions;
  },
  [LAYOUT_DESIGNER_SET_LAYOUT]: (state, { layout }) => {
    Vue.set(state, 'layout', layout);

    state.isLayoutHorizontal = !!layout.settings.isHorizontal;
  },
};

export default {
  getters,
  state,
  actions,
  mutations,
};
