import {
    RSS_REQUEST,
    RSS_REQUEST_ERROR,
    RSS_REQUEST_SUCCESS,
    RSS_FEED_URL,
} from '../actions/rss';

import {
    apiGetRss
} from '../../api/rss'
import Vue from 'vue'

const getters = {
}

const actions = {
    [RSS_REQUEST]: ({commit}, feed_url) => {
        commit(RSS_REQUEST);
        var p = apiGetRss(feed_url)
        p.then((resp) => {
            commit(RSS_REQUEST_SUCCESS, resp)
        }).catch((error) => {
            commit(RSS_REQUEST_ERROR, error)
        });
        return p
    }
};

const getDefaultState = () => {
    return {
        rssFeed: {},
        rssFeedUrl: null,
        rssRequestStatus: '',
        rssRequestError: '',
    }
}

const state = getDefaultState();

const mutations = {
    [RSS_REQUEST]: (state) => {
        state.rssRequestStatus = 'loading'
    },
    [RSS_REQUEST_SUCCESS]: (state, resp) => {
        Vue.set(state, 'rssFeed', resp.data);
        state.rssRequestStatus = 'success';
    },
    [RSS_REQUEST_ERROR]: (error) => {
        state.rssRequestError = error.response.data.message;
        state.rssRequestStatus = 'error';
    },
    [RSS_FEED_URL]: (state, url) => {
        state.rssFeedUrl = url;
    },
};

export default {
    getters,
    state,
    actions,
    mutations
}
