import config from '../config';
import axios from 'axios';

export function apiGetScreenLayout(screenId) {
  const endpoint = `${config.apiUrl}userapi/screens/${screenId}/layout`;
  return axios.get(endpoint);
}

export const apiGetScreenLayoutChildren = async (screenId) => {
  return await axios.get(`${config.apiUrl}userapi/screens/${screenId}/layout/children`);
};

export const apiGetScreenLayoutMediaAsset = async (screenId) => {
  const response = await axios.get(
    `${config.apiUrl}userapi/screens/${screenId}/layout/media-asset`,
  );

  return response.data;
};
