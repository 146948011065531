import config from '../config';
import axios from 'axios';

export async function apiGetScreenInternalStorage(screenId) {
  const response = await axios.get(`${config.apiUrl}userapi/screens/${screenId}/internal-storage`);

  return response.data;
}

export async function apiCreateScreenInternalStorage(screenId, data) {
  const response = await axios.post(
    `${config.apiUrl}userapi/screens/${screenId}/internal-storage`,
    {
      screenId: screenId,
      fileDownloadHistory: data.fileDownloadHistory,
      internalFiles: data.internalFiles,
    },
  );

  return response.data;
}

export async function apiUpdateScreenInternalStorage(screenId, data) {
  const response = await axios.put(`${config.apiUrl}userapi/screens/${screenId}/internal-storage`, {
    id: data.id,
    screenId: screenId,
    fileDownloadHistory: data.fileDownloadHistory,
    internalFiles: data.internalFiles,
  });

  return response.data;
}
