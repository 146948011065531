export const WEBOS_INTERNAL_RELATIVE_PATH = 'file://internal';
export const WEBOS_RELATIVE_PATH = 'http://127.0.0.1:9080';

export const WEBOS_URI_MAX_LENGTH = 255;

export const WEBOS_FILE_TYPE = Object.freeze({
  Folder: 'folder',
});

export const WEBOS_DOWNLOAD_STATUS = Object.freeze({
  Downloading: 'downloading',
  Completed: 'completed',
  Paused: 'paused',
  Canceled: 'canceled',
  Failed: 'failed',
});

export const WEBOS_DOWNLOAD_ACTION = Object.freeze({
  Start: 'start',
  Pause: 'pause',
  Resume: 'resume',
  Canceled: 'cancel',
});

export const PICFLOW_INTERNAL_STORAGE_QUOTA_IN_PERCENT = 0.8;
export const PICFLOW_MAX_NUMBER_OF_FILES_IN_INTERNAL_STORAGE = 50;

export const PICFLOW_SUPPORTED_WEBOS_VERSION = Object.freeze({
  '3.0': '3.0',
  3.2: '3.2',
  '4.0': '4.0',
  4.1: '4.1',
  '6.0': '6.0',
});

export const PICFLOW_SCAP_API_PATH = Object.freeze({
  '3.0': 'webos/cordova-cd/scap1.5/',
  3.2: 'webos/cordova-cd/scap1.5/',
  '4.0': 'webos/cordova-cd/scap1.8/',
  4.1: 'webos/cordova-cd/scap1.8/',
  '6.0': 'webos/cordova-cd/scap1.8/',
});

export const PICFLOW_SCAP_API_FILES = Object.freeze({
  DeviceInfo: 'deviceInfo.js',
  Storage: 'storage.js',
  Signage: 'signage.js',
});

export const PICFLOW_WEBOS_EXTERNAL_SCRIPT_PATH = Object.freeze({
  CordovaLib: 'webos/cordova/2.7.0/cordova.webos.js',
  CustomLib: 'webos/custom14.js',
  WebOSLib: 'webos/webOS/webOS.js',
  UtilsLib: 'webos/utils.js',
  WebClientLib: 'webos/webClient.js',
});
