import Vue from 'vue';
import { arrayToObjectWithIdsAsKeys, getWidgetItemType } from '@/helpers/utils';
import {
  RESET_PLAYER,
  PLAYER_LOAD_LAYOUT,
  PLAYER_LOAD_LAYOUT_CHILDREN,
  PLAYER_LOAD_LAYOUT_ERROR,
  PLAYER_SET_LAYOUT,
  SET_LAYOUT_CHILDREN,
  SET_PLAYER_ROTATION,
  SET_SCREEN_SIZE,
  PLAYER_SET_LAYOUT_ORIENTATION,
  OPEN_PLAYER_MODAL,
  CLOSE_PLAYER_MODAL,
} from '@/store/actions/player';
import { apiGetScreenLayout, apiGetScreenLayoutChildren } from '@/api/layouts';
import { LAYOUT_DESIGNER_SET_LAYOUT } from '../actions/layoutDesigner';
import { LAYOUT_RESOLUTIONS, WIDGET_TYPES } from '@/models/layoutDesigner';

const initialState = {
  loadingLayout: false,
  loadingLayoutError: null,
  layout: null,
  widgets: {},
  isPlayerHorizontal: false,
  isPreviewPlayer: false,
  screenSize: {
    width: window.innerWidth || document.documentElement.clientWidth || document.body.clientWidth,
    height:
      window.innerHeight || document.documentElement.clientHeight || document.body.clientHeight,
  },
  screenId: null,
  showPlayerModal: false,
  modalContent: null,
};

const getters = {
  getPlayerRotation: (state) => (state.isPlayerHorizontal ? 'horizontal' : 'vertical'),
  widgetsArray: (state) => {
    return Object.values(state.widgets).sort(
      (widgetA, widgetB) => widgetA.position.horizontal.zIndex > widgetB.position.horizontal.zIndex,
    );
  },
};

const actions = {
  [PLAYER_LOAD_LAYOUT]: async ({ commit, dispatch }, { loadChildren = false, screenId }) => {
    try {
      commit(RESET_PLAYER, { screenId });

      commit(PLAYER_LOAD_LAYOUT);
      const response = await apiGetScreenLayout(screenId);
      const layout = response.data;

      commit(PLAYER_SET_LAYOUT, layout);
      commit(PLAYER_SET_LAYOUT_ORIENTATION, layout.settings.isHorizontal);

      // Forcing screen resolution on Screen
      const { aspectRatio, resolution, isHorizontal } = layout.settings;
      const { width, height } = LAYOUT_RESOLUTIONS.resolutions[aspectRatio][resolution];

      document.getElementById('viewport').setAttribute(
        'content',
        `user-scalable=no,
           height=${isHorizontal ? height : width},
           width=${isHorizontal ? width : height}`,
      );

      if (loadChildren || screenId) {
        await dispatch(PLAYER_LOAD_LAYOUT_CHILDREN, screenId);
      }

      commit(LAYOUT_DESIGNER_SET_LAYOUT, { layout }, { root: true });

      return layout;
    } catch (error) {
      commit(PLAYER_LOAD_LAYOUT_ERROR, error);
      console.log('error: ', error);
      return false;
    }
  },

  [PLAYER_LOAD_LAYOUT_CHILDREN]: async ({ commit }, screenId) => {
    try {
      const response = await apiGetScreenLayoutChildren(screenId);

      const formatedWidgets = response.data.map((widget) => {
        const isAnApp = widget.object.item_type ? widget.object.item_type.includes('app') : false;

        let itemType = getWidgetItemType(widget);

        if (isAnApp) {
          if (widget.object.config && widget.object.config.override) {
            delete widget.object.config.override;
          }

          if (widget.object.override && widget.object.override.config.override) {
            delete widget.object.override.config.override;
          }
        }

        let layouObject = isAnApp
          ? Object.assign({}, widget.object, widget.object.config)
          : widget.object;

        if (widget.object.override) {
          if (
            itemType === WIDGET_TYPES.TEXT ||
            itemType === WIDGET_TYPES.LAYER ||
            itemType === WIDGET_TYPES.RICH_TEXT ||
            (itemType === WIDGET_TYPES.APP && layouObject.item_type.includes('table'))
          ) {
            layouObject = { ...layouObject, ...layouObject.override.config };
          }
        }

        return Object.assign({}, widget, {
          assoc_id: widget.assoc_id,
          originalObject: widget.object,
          object: Object.assign({}, layouObject, { type: itemType }),
          itemType,
        });
      });

      let fileCount = 0;
      const files = {};

      formatedWidgets.forEach((widget) => {
        if (widget.object.type === 'video') {
          fileCount++;
          files[widget.object.item_id] = widget.position.data.url;
        }
      });

      if (window.isWebOS === true && fileCount > 0) {
        // TODO: implement disk cache for images and videos in WebOS. This functionality is essential for reducing data traffic out of AWS
        console.warn('Disk cache for WebOS is not implemented yet.');

        commit(SET_LAYOUT_CHILDREN, arrayToObjectWithIdsAsKeys(formatedWidgets, 'assoc_id'));
      } else if (window.isAndroid === true && fileCount > 0) {
        const successCb = (res) => {
          const downloadedFiles = res.files;
          const cachedWidgets = formatedWidgets.map((widget) => {
            if (widget.object.type === 'video') {
              if (downloadedFiles[widget.object.item_id]) {
                widget.position.data.url = downloadedFiles[widget.object.item_id];
              }
            }
            return widget;
          });
          commit(SET_LAYOUT_CHILDREN, arrayToObjectWithIdsAsKeys(cachedWidgets, 'assoc_id'));
        };

        const failureCb = (err) => {
          commit(SET_LAYOUT_CHILDREN, arrayToObjectWithIdsAsKeys(formatedWidgets, 'assoc_id'));
        };

        // Set result call back for android
        window.finishDownload = (res) => {
          let result = JSON.parse(res);
          if (result.error) {
            failureCb(result.error);
          } else {
            successCb(result);
          }
        };

        // Request download files on android
        if (window.downloadFiles) window.downloadFiles(files);
      } else {
        commit(SET_LAYOUT_CHILDREN, arrayToObjectWithIdsAsKeys(formatedWidgets, 'assoc_id'));
      }

      return formatedWidgets;
    } catch (error) {
      commit(PLAYER_LOAD_LAYOUT_ERROR, error);
      console.error(error);
      return false;
    }
  },
};

const mutations = {
  [PLAYER_LOAD_LAYOUT]: (state) => {
    state.loadingLayout = true;
    state.loadingLayoutError = null;
  },

  [PLAYER_SET_LAYOUT]: (state, payload) => {
    state.loadingLayoutError = null;
    state.layout = payload;
  },

  [PLAYER_LOAD_LAYOUT_ERROR]: (state, error) => {
    state.loadingLayout = false;
    state.loadingLayoutError = error;
  },

  [RESET_PLAYER]: (state, { screenId = null }) => {
    state.layout = null;
    state.selectedWidget = null;
    state.widgets = {};
    state.screenId = screenId;
  },

  [OPEN_PLAYER_MODAL]: (state, modalContent) => {
    state.showPlayerModal = true;
    state.modalContent = modalContent;
  },

  [CLOSE_PLAYER_MODAL]: (state) => {
    state.showPlayerModal = false;
    state.modalContent = null;
  },

  [SET_LAYOUT_CHILDREN]: (state, layoutChildren) => {
    state.loadingLayout = false;
    Vue.set(state, 'widgets', layoutChildren);
  },

  [SET_PLAYER_ROTATION]: (state, value = false) => {
    if (state.layout) {
      const layout = JSON.parse(JSON.stringify(state.layout));
      layout.settings.isHorizontal = value;
      Vue.set(state, 'layout', layout);
    }
    state.isPlayerHorizontal = value;
  },
  [SET_SCREEN_SIZE]: (state, value) => {
    state.screenSize = value;
  },
  [PLAYER_SET_LAYOUT_ORIENTATION]: (state, value) => {
    state.isPlayerHorizontal = value;
  },
};

export default {
  state: initialState,
  actions,
  mutations,
  getters,
};
