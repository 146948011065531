import config from "../config";
import http from "@/utils/http";

export const apiGetRss = (rss_url) =>
  new Promise((resolve, reject) => {
    var apiUrl = `${config.apiUrl}userapi/app/demo/rss?url=${rss_url}`;

    var p = http
      .get(apiUrl)
      .then((resp) => {
        resolve(resp);
      })
      .catch((error) => {
        reject(error);
      });
    return p;
  });
