<template>
  <svg
    v-if="icon"
    :viewBox="`0 0 ${icon.width} ${icon.height}`"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    class="full-size"
  >
    <template v-if="icon.g">
      <g :clip-path="icon.g.clipPath">
        <path
          v-for="(path, index) in icon.g.paths"
          fill-rule="evenodd"
          clip-rule="evenodd"
          :d="path.d"
          :fill="color"
          :key="index"
        />
      </g>
      <defs>
        <clipPath :id="icon.g.id">
          <template v-for="(item, index) in icon.g.draw">
            <rect v-show="item.type === 'rect'" :fill="item.fill" :key="`${icon.id}-${index}`" />
          </template>
        </clipPath>
      </defs>
    </template>

    <template v-else>
      <path
        v-for="(path, index) in icon.paths"
        fill-rule="evenodd"
        clip-rule="evenodd"
        :d="path.d"
        :fill="color"
        :key="index"
      />
    </template>
  </svg>
</template>

<script>
  import { weatherIcons } from '@/constants/weatherApp';

  export default {
    name: 'WeatherIcons',

    props: {
      iconId: {
        type: Number,
        default: null,
      },
      color: {
        type: String,
        default: '#151515',
      },
    },

    computed: {
      icon() {
        return weatherIcons[this.iconId];
      },
    },
  };
</script>

<style lang="scss" scoped>
  .full-size {
    height: 100%;
    width: 100%;
  }
</style>
