import config from '../config';
import axios from 'axios';

axios.defaults.headers.common['Authorization'] = localStorage.getItem('user-session_token');

export function apiGetTile(tileId) {
  const endpoint = `${config.apiUrl}userapi/local-templates/${tileId}`;
  return axios.get(endpoint);
}

export function apiGetScreenTile(tileId) {
  const endpoint = `${config.apiUrl}userapi/local-template-overrides/${tileId}`;
  return axios.get(endpoint);
}

// ---- TILE CHILDREN ---- //

export function apiGetTileChildren(tileId) {
  const endpoint = `${config.apiUrl}userapi/local-templates/${tileId}/children`;
  return axios.get(endpoint);
}

// ---- Tile OVERRIDES ---- //

export const apiGetScreenTileChildren = async (tileId, screenId, playlistId) => {
  const url = `${config.apiUrl}userapi/screens/${screenId}/local-template/${tileId}/children?playlist_id=${playlistId}`;

  return axios.get(url);
};
