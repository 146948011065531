import config from '../config';
import http from '@/utils/http';

export const saveScreenInformation = (info) => {
  const apiUrl = `${config.apiUrl}userapi/player/screen-information`;
  return http.patch(apiUrl, info);
};

export const getScreenInformation = () => {
  const apiUrl = `${config.apiUrl}userapi/player/screen-information`;
  return http.get(apiUrl);
};
