<template>
  <div class="login">
    <img src="@/assets/img/picflow-dark-logo.svg" class="logo" alt="Picflow logo" />
    <div class="code-options">
      <div class="generate">
        <div class="hint">
          <span> Generate the code and enter in </span>
          <span class="cms">app.picler.se</span>
        </div>
        <button @click="getGeneratedCode" id="generate-code">
          <img
            v-if="loading && isGenerateSelected"
            class="button-loader"
            src="@/assets/loader-white.svg"
            alt="Loading"
          />
          <span v-else>Generate Code</span>
        </button>
        <div class="code" v-if="generatedCode">{{ generatedCode }}</div>
      </div>
      <div class="separator-container">
        <hr />
        <span>or</span>
        <hr />
      </div>
      <div class="enter">
        <div class="hint">
          <span> Enter the code provided to you by </span>
          <span class="cms">app.picler.se</span>
        </div>
        <form @submit.prevent="register">
          <div class="input-group">
            <input id="register-code" type="number" placeholder="Ex. 345531" v-model="code" />
            <div class="button">
              <img
                v-if="loading && !isGenerateSelected"
                class="button-loader"
                src="@/assets/loader-white.svg"
                alt="Loading"
              />
              <span v-else>Apply</span>
            </div>
          </div>
        </form>
      </div>
    </div>
    <img class="water-mark" src="@/assets/img/login/water-mark.svg" alt="Picflow Watermark" />
  </div>
</template>

<script>
  import { SET_LOADER } from '@/store/actions/login';
  export default {
    name: 'RegisterCode',
    components: {},
    data() {
      return {
        code: null,
        isGenerateSelected: true,
      };
    },
    computed: {
      generatedCode() {
        return this.$store.getters.getGeneratedCode;
      },
      loading() {
        return this.$store.getters.getIsLoading;
      },
    },
    mounted() {
      document.getElementById('generate-code').focus();
      const self = this;
      document.onkeydown = function (event) {
        const keyCode = event.keyCode;
        if (keyCode === 40) {
          self.isGenerateSelected = false;
          if (document.getElementById('register-code')) {
            event.preventDefault();
            document.getElementById('register-code').focus();
          }
        } else if (keyCode === 38) {
          self.isGenerateSelected = true;
          if (document.getElementById('generate-code')) {
            event.preventDefault();
            document.getElementById('generate-code').focus();
          }
        }
      };
    },
    methods: {
      register() {
        this.$store.commit(SET_LOADER, true);
        //we only emit the code validation here. join and other staffs are done from socket events.
        this.$socket.emit('validate_screen_code', { code: this.code });
      },
      getGeneratedCode() {
        if (!this.generatedCode) {
          this.$store.commit(SET_LOADER, true);
          this.$socket.emit('generate_screen_code');
        }
      },
    },
  };
</script>

<style lang="scss" scoped>
  $primary-red: #e3003a;
  @mixin button-style {
    border-radius: 8px;
    background-color: #f1809d;
    color: #fff;
    font-size: 32px;
    font-weight: 500;
    line-height: 48px;
    border: none;
    padding: 16px;
    &:focus,
    &.active {
      background-color: $primary-red;
    }
    height: 100px;
    width: 680px;
  }
  @mixin active-elements-font {
    font-weight: 500;
    font-size: 42px;
    line-height: 64px;
  }
  .login {
    font-family: 'Poppins';
    display: flex;
    flex-direction: column;
    height: 100vh;
    width: 100vw;
    background-color: #fff7bc;
    align-items: center;
    padding-top: 80px;
    .water-mark {
      position: fixed;
      bottom: 0;
      right: 0;
    }
    .logo {
      width: 280px;
      margin-bottom: 200px;
    }
    .code-options {
      display: flex;
      flex-direction: column;
      .generate,
      .enter {
        display: flex;
        flex-direction: column;
      }
      .separator-container {
        display: flex;
        justify-content: space-between;
        align-items: center;
        opacity: 0.2;
        font-size: 32px;
        line-height: 40px;
        span {
          margin: 0 16px;
        }

        hr {
          width: 100%;
          border-top: 2px #151515 solid;
          background: none;
        }
      }
      .generate {
        margin-right: 0;
        margin-bottom: 130px;
        button {
          @include button-style;
          margin-top: 24px;
          outline: none;
        }
        .code {
          @include button-style;
          @include active-elements-font;
          background-color: #feef93;
          text-align: center;
          border: 2px solid #f2df96;
          color: $primary-red;
          letter-spacing: 4px;
          width: 680px;
          margin-top: 24px;
        }
      }
      .enter {
        margin-left: 0;
        margin-top: 130px;
        .input-group {
          margin-top: 24px;
          display: flex;
          flex-direction: row;
          width: 680px;
          input {
            @include active-elements-font;
            text-align: center;
            width: 70%;
            outline: none;
            border: none;
            padding: 14px 24px;
            border-radius: 8px 0 0 8px;
            border: 2px solid #d0d0d0;
            border-right: none;
            &:focus {
              border: 2px solid $primary-red;
            }
            &:focus + .button {
              background-color: $primary-red;
            }
            letter-spacing: 2px;
            &::-webkit-outer-spin-button,
            &::-webkit-inner-spin-button {
              /* display: none; <- Crashes Chrome on hover */
              -webkit-appearance: none;
              margin: 0;
              /* <-- Apparently some margin are still there even though it's hidden */
            }
          }
          .button {
            @include button-style;
            border-radius: 0 8px 8px 0;
            width: 30%;
            align-items: center;
            display: flex;
            justify-content: center;
          }
        }
      }
      .hint {
        font-weight: 400;
        font-size: 32px;
        line-height: 40px;
        display: flex;
        flex-direction: column;
        align-items: center;
        .cms {
          font-weight: 700;
        }
      }
      .button-loader {
        height: 32px;
      }
    }
  }
  @media screen and (min-width: 1408px) {
    .login {
      .logo {
        margin-bottom: 80px;
      }
      .code-options {
        flex-direction: row;
        .generate {
          margin-right: 80px;
          margin-bottom: 0;
          button {
            width: 580px;
          }
          .code {
            width: 580px;
          }
        }
        .enter {
          margin-left: 80px;
          margin-top: 0;
          .input-group {
            width: 580px;
          }
        }
        .separator-container {
          display: flex;
          flex-direction: column;
          height: 75vh;
          span {
            margin: 0;
          }

          hr {
            margin: 16px 0;
            width: 0;
            border-left: 2px #151515 solid;
            border-top: none;
            background: none;
            &:first-child {
              height: 20%;
            }
            &:last-child {
              height: 80%;
            }
          }
        }
      }
    }
  }
</style>
