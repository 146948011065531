<template>
  <div :style="style">
    <video
      class="widget-video"
      :style="videoStyle"
      loop
      :muted="widget.object.mute"
      texture
      :ref="`widget_video_${widget.position.data.wid}`"
      @click="toggleVideo()"
    >
      <source :src="videoUrl" :type="widget.object.item_type" @error="handleVideoError" />
      <source
        v-if="!isWeb"
        :src="defaultVideoUrl"
        :type="widget.object.item_type"
        @error="handleDefaultVideoError"
      />
    </video>
  </div>
</template>

<script>
  import templateWidget from '@/components/widgets/templates/mixins/templateWidget';
  import { simpleTypeMixin } from '@/helpers';
  import { getItemLocalUrl, getItemUrl } from '@/utils/mediaAsset';
  import { getVideoWidgetItemId, getVideoWidgetItemUrl } from '@/utils/videoWidget';

  export default {
    name: 'VideoWidget',

    mixins: [templateWidget, simpleTypeMixin],

    props: {
      widget: {
        type: Object,
        default: () => ({}),
      },
    },

    mounted() {
      this.playVideo();
    },

    computed: {
      style() {
        return Object.assign({}, this.defaultStyling(this.widget), {
          position: 'absolute',
          overflow: 'hidden',
        });
      },

      isWeb() {
        return window.isWeb;
      },

      videoUrl() {
        return getItemLocalUrl(
          getVideoWidgetItemUrl(this.widget),
          getVideoWidgetItemId(this.widget),
        );
      },

      defaultVideoUrl() {
        return getItemUrl(getVideoWidgetItemUrl(this.widget));
      },

      videoStyle() {
        return {
          objectFit: this.widget.position.data.imageMode || 'cover',
        };
      },
    },

    methods: {
      playVideo() {
        this.$refs[`widget_video_${this.widget.position.data.wid}`].currentTime = 0;
        const playPromise = this.$refs[`widget_video_${this.widget.position.data.wid}`].play();

        // browsers released before 2019 may not return a value
        if (playPromise !== undefined) {
          playPromise.then((_) => {}).catch((_error) => {});
        }
      },

      toggleVideo() {
        if (this.isPlaying) {
          this.playVideo();
        } else {
          this.pauseVideo();
        }
      },

      pauseVideo() {
        this.$refs[`widget_video_${this.widget.position.data.wid}`].pause();
      },

      handleVideoError() {
        console.warn(`Failed to load video: ${this.videoUrl}`);
      },

      handleDefaultVideoError() {
        console.warn(`Failed to load default video: ${this.defaultVideoUrl}`);
      },

      showImage() {
        //this.$emit('show-enlarged', this.widget)
      },
    },
  };
</script>

<style lang="scss" scoped>
  .widget-video {
    width: 100%;
    height: 100%;
  }
</style>
