<template>
    <div class="web-player-v2">
        <!-- <div class="text-center pt-5" style="color: white;">
            <h1>Refreshing token...</h1>
        </div> -->
        <Loader />
    </div>
</template>
  
<script>
import Loader from "../components/common/Loader.vue";

export default {
    name: 'RefreshTokenText',
    components: { Loader },
    mounted() {
        document.body.classList.add('web-player-body-v2');
    },
    beforeDestroy() {
        document.body.classList.remove('web-player-body-v2');
    },
};
</script>
  
<style lang="scss">
body.web-player-body-v2 {
    margin: 0;
    padding: 0;
    height: 100vh;
    width: 100%;
    overflow: hidden;

    #mainAppWrapper {
        height: 100%;
        width: 100%;
        overflow: hidden;
        padding: 0;
        margin: 0;
    }
}
</style>
  
<style lang="scss" scoped>
.web-player-v2 {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 100vh;
    background-color: #000;
    color: #fff;
    overflow: hidden;
}

pre {
    font-size: 1rem;
    height: 100%;
    overflow: auto;
    background-color: #000;
    color: #fff;
}
</style>
  