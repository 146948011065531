<template>
  <div
    ref="templateContainer"
    v-if="template"
    class="template-main-container"
    :style="templateStyle"
  >
    <template v-if="!isLoading">
      <TemplateLayer v-for="widget in widgets" :key="widget.assoc_id" :widget="widget" />
    </template>
    <div v-else class="loader">
      <div class="loader-background">
        <Loader />
      </div>
    </div>
  </div>
</template>

<script>
  import TemplateLayer from '@/components/widgets/templates/TemplateLayer.vue';
  import { apiGetScreenTemplateChildren } from '@/api/templates';
  import { formatTemplateWidgets, getRBGAColor } from '@/helpers/utils';
  import Loader from '@/components/common/Loader.vue';

  export default {
    name: 'TemplateViewer',

    components: {
      TemplateLayer,
      Loader,
    },

    props: {
      template: {
        type: Object,
        default: () => null,
      },

      zoom: {
        type: Number,
        default: 1,
      },

      playlistId: {
        type: String,
        default: '',
      },
    },

    data() {
      return {
        widgets: [],
        isLoading: false,
      };
    },

    mounted() {
      this.getWidgets();
    },

    watch: {
      template(value) {
        if (value) {
          this.getWidgets();
        }
      },
    },

    computed: {
      templateStyle() {
        const template = this.template?.settings || {};

        return {
          backgroundColor: getRBGAColor(template.backgroundColor, template.opacity / 100),
          width: `100%`,
          height: `100%`,
          overflow: 'hidden',
          zoom: this.zoom,
        };
      },
    },

    methods: {
      async getWidgets() {
        try {
          const screenId = this.$store.state.player.screenId;
          this.isLoading = true;
          this.widgets = [];

          const response = await apiGetScreenTemplateChildren(
            this.template.template_id,
            screenId,
            this.playlistId,
          );

          const formatedWidgets = formatTemplateWidgets(response.data);

          this.widgets = formatedWidgets;
          this.isLoading = false;
        } catch (error) {
          console.log('getWidgets ~ error:', error);

          this.$bvToast.toast('Not able to load Template Preview', {
            title: 'Error',
            solid: true,
            variant: 'danger',
          });
        }
      },
    },

    provide() {
      return {
        template: () => this.template,
        isTemplateOnPlaylist: () => !!this.playlistId,
      };
    },
  };
</script>

<style lang="scss" scoped>
  .template-main-container {
    width: 100%;
    height: 100%;
    position: relative;
  }

  .loader {
    display: flex;
    justify-content: center;
    align-items: center;

    height: 100%;
    width: 100%;
  }
</style>
