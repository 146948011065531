var render = function render(){var _vm=this,_c=_vm._self._c;return (_vm.isLoading)?_c('Loader'):(_vm.currentSlide && _vm.filteredSlides.length > 0)?_c('div',{staticClass:"playlist-player"},_vm._l((_vm.filteredSlides),function(slide,index){return _c('div',{key:index,staticClass:"playlist-slide",class:{
      [_vm.slideClassIn]: _vm.displaySlide(slide),
      [_vm.slideClassOut]: !_vm.displaySlide(slide),
    }},[(
        (!_vm.isCurrentItemVideoSlide && index === _vm.currentSlideIndex) ||
        (!_vm.isNextItemVideoSlide && index === _vm.nextSlideIndex)
      )?_c('PlaylistSlide',{key:`${slide.item_id}-${slide.assoc_id}-${index}`,attrs:{"slide":{ ...slide, imageMode: _vm.playlist.imageMode || 'cover' },"isCurrentSlide":slide.item_id === _vm.currentSlide.item_id,"trafiklabZoom":_vm.trafiklabZoom,"playlist":_vm.playlist}}):_vm._e(),(
        (_vm.isCurrentItemVideoSlide && index === _vm.currentSlideIndex) ||
        (_vm.isNextItemVideoSlide && index === _vm.nextSlideIndex)
      )?_c('VideoSlide',{key:`${slide.item_id}-${slide.assoc_id}-${index}`,attrs:{"show":slide.item_id === _vm.currentSlide.item_id,"restartSignal":_vm.restartVideo,"itemId":slide.item_id,"itemType":slide?.item_type ?? 'cover',"itemUrl":slide.item_url}}):_vm._e()],1)}),0):_c('div',{staticClass:"playlist-background"})
}
var staticRenderFns = []

export { render, staticRenderFns }