<template>
  <div class="web-player-v2">
    <div class="initializing-overlay text-center pt-5" v-if="isLoading">
      <h1>Checking for layout...</h1>
    </div>
    <div class="initializing-overlay text-center pt-5" v-else>
      <h1>No layout is attached with this screen. Please refresh.</h1>
    </div>
    <div v-if="!isLoading" class="text-center pt-5">
      <button @click.prevent="reload" class="btn btn-warning">Reload</button>
    </div>
  </div>
</template>

<script>
  import localforage from 'localforage';

  import { SCREEN_ID_ENUM, SCREEN_LAYOUT_ENUM } from '@/constants';
  import { fetchScreenLayout } from '@/services/layout';

  export default {
    name: 'NoLayout',

    data() {
      return {
        isLoading: false,
      };
    },

    methods: {
      async reload() {
        const screenId = localStorage.getItem(SCREEN_ID_ENUM);
        this.isLoading = true;
        const result = await fetchScreenLayout(screenId);
        this.isLoading = false;

        if (result.success && result.data.status === 200) {
          localStorage.setItem(SCREEN_LAYOUT_ENUM, result.data.data.layout_id);
          localforage.setItem(SCREEN_LAYOUT_ENUM, result.data.data.layout_id);

          this.$router.push(`/player/${result.data.data.layout_id}`);
        } else if (result.success && result.data.status === 204) {
          localStorage.setItem(SCREEN_LAYOUT_ENUM, '');
          localforage.setItem(SCREEN_LAYOUT_ENUM, '');

          this.$bvToast.toast('No Layout available!', {
            title: 'Warning!',
            solid: true,
            variant: 'warning',
          });
        } else {
          this.$bvToast.toast(result.message, {
            title: 'Error!',
            solid: true,
            variant: 'danger',
          });
          // this.refreshToken();
        }
      },
    },
    mounted() {
      document.body.classList.add('web-player-body-v2');
    },
    beforeDestroy() {
      document.body.classList.remove('web-player-body-v2');
    },
  };
</script>

<style lang="scss">
  body.web-player-body-v2 {
    margin: 0;
    padding: 0;
    height: 100vh;
    width: 100%;
    overflow: hidden;

    #mainAppWrapper {
      height: 100%;
      width: 100%;
      overflow: hidden;
      padding: 0;
      margin: 0;
    }
  }
</style>

<style lang="scss" scoped>
  .web-player-v2 {
    position: relative;
    width: 100%;
    height: 100vh;
    background-color: #000;
    color: #fff;
    overflow: hidden;

    ::v-deep .widget-item {
      position: absolute;
    }
  }

  pre {
    font-size: 1rem;
    height: 100%;
    overflow: auto;
    background-color: #000;
    color: #fff;
  }
</style>
